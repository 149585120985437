import { ACTIONS } from './actions';
import initialState from './initialState';
import lodashSet from 'lodash/set';

const mainReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
      case ACTIONS.ROUTER_SET_REDIRECT:
          newState.routerRedirect = action.payload;
          return newState;
      case ACTIONS.ROUTER_UNSET_REDIRECT:
          newState.routerRedirect = '';
          return newState;
      case ACTIONS.SET_LANGUAGE:
          newState.languageKey = action.payload;
          return newState;
      case ACTIONS.GENERIC_SET_VALUE:
          newState = { ...state };
          for (let item of action.payload) {
              const path = item.path;
              const value = item.value;
              lodashSet(newState, path, value);
          }
          return newState;
    default:
      return state;
  }
};

export default mainReducer;
