import React from 'react';
import styled from 'styled-components';
import { getLanguageValue} from "../common/functions";
import CheckIcon from '@material-ui/icons/Check';
import StepsBar from './StepsBar';

function HowDoIApply(props) {

    let progressBarItems = [ ];

    for (let loop = 1 ; loop <= 9 ; ++loop) {
        // skip 4
        if (loop != 4) {
            progressBarItems.push({ text: getLanguageValue('how-to-apply.progressbar-' + loop), icon: <CheckIcon className="progress-icon" /> });
        }
    }

    return <HowDoIApplyStyle className={props.className || ''} >
        <div className="how-to-apply-title">{getLanguageValue('how-to-apply.title')}</div>
        <StepsBar items={progressBarItems} itemPercentage={parseInt(100 / progressBarItems.length)} />
    </HowDoIApplyStyle>;
}

const HowDoIApplyStyle = styled.div`
  background-color: #1c3764;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-right: 5px;
  padding-left: 5px;
  .how-to-apply-title {
    max-width: 1100px;
    color: white;
    margin: 0 auto;
    margin-bottom: 25px;
    display: flex;
    align-items: stretch;
  }
`;

export default HowDoIApply;