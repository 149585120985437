function emptyValueValidator(value) {
    if (value) {
        return true;
    }
    return false;
}

function stringValueValidator(value) {
    try {
        const languageKey = this.languageKey || window.LANGUAGE.key;
        value = value.trim();
        const REGEX_BY_LANGUAGE_HASH = {
            he: /^([\u0590-\u05FF\"\'\.\-,\s]+)$/g,
            en: /^([a-zA-Z\"\'\.\-,\s]+)$/g,
            heAndNumeric: /^([\u0590-\u05FF\"\'\.\-,\s0-9]+)$/g,
            enAndNumeric: /^([a-zA-Z\"\'\.\-,\s0-9]+)$/g,
            default: /^([\u0590-\u05FF\"\'\.\-,\s]+)|([a-zA-Z\"\'\.\-,\s]+)$/g,
        };
        if (this.languageKey == 'all') {
            if (!REGEX_BY_LANGUAGE_HASH['en'].test(value)) {
                if (!REGEX_BY_LANGUAGE_HASH['he'].test(value)) {
                    return false;
                }
            }
            return true;
        }
        if (this.languageKey == 'allAndNumeric') {
            if (!REGEX_BY_LANGUAGE_HASH['enAndNumeric'].test(value)) {
                if (!REGEX_BY_LANGUAGE_HASH['heAndNumeric'].test(value)) {
                    return false;
                }
            }
            return true;
        }
        return REGEX_BY_LANGUAGE_HASH[languageKey].test(value);
    }
    catch (err) {
        // nothing to do
    }
    return false;
}

function numericValueValidator(value) {
    return /^[0-9]+$/g.test(value);
}

function returnTrue() {
    return true;
}

export {
    emptyValueValidator,
    stringValueValidator,
    numericValueValidator,
    returnTrue
};