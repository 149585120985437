import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, notifyClientError} from "../../common/functions";
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashCloneDeep from 'lodash/cloneDeep';
import CONSTANTS from '../../common/constants';
import GenericFieldComponent from "../GenericFieldComponent";
import RegisterGroupStage1SubProcess from './RegisterGroupStage1SubProcess';
import moment from 'moment';
import { ACTIONS } from '../../redux/actions';
import {CSS_COLORS} from "../../common/cssColors";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

function RegisterStage1Component(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const {
        actions
    } = props;

    const [ fieldsData, setFieldsData ] = useState({
        lastName: '',
        firstName: '',
        organization: '',
        birthDate: null,
        email: '',
        emailConfirm: '',
        city: '',
        addressLine: '',

        // override with props data
        ...props.stage1Data
    });

    const [ registerSubProcessOpened, setRegisterSubProcessOpened ] = useState(false);
    const [ registerSubProcessActions, setRegisterSubProcessActions ] = useState({ });
    let [ fieldErrors, setFieldErrors ] = useState({});
    let [ globalErrors, setGlobalErrors ] = useState({});

    const FIELDS = [
        {
            key: 'lastName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.lastName ? ' field-error' : ''),
                    error: fieldErrors.lastName,
                    value: fieldsData.lastName,
                    inputProps: {
                        name: 'lastName'
                    },
                    helperText: fieldErrors.lastName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'lastName' }),
                    label: getLanguageValue('register-group.stage1-family-name') + ' *'
                }
            }
        },
        {
            key: 'firstName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.firstName ? ' field-error' : ''),
                    error: fieldErrors.firstName,
                    value: fieldsData.firstName,
                    inputProps: {
                        name: 'firstName'
                    },
                    helperText: fieldErrors.firstName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'firstName' }),
                    label: getLanguageValue('register-group.stage1-first-name') + ' *'
                }
            }
        },
        {
            key: 'organization',
            validators: [
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.organization ? ' field-error' : ''),
                    error: fieldErrors.organization,
                    value: fieldsData.organization,
                    inputProps: {
                        name: 'organization'
                    },
                    helperText: fieldErrors.organization || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'organization' }),
                    label: getLanguageValue('register-group.stage1-organization')
                }
            }
        },
        {
            key: 'birthDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#birthDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.birthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.birthDate || false,
                    autoOk: true,
                    value: fieldsData.birthDate ? fieldsData.birthDate : null,
                    inputProps: {
                        name: 'birthDate',
                        id: 'birthDate'
                    },
                    helperText: fieldErrors.birthDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'birthDate' }),
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'birthDate' }),
                    label: getLanguageValue('register-group.stage1-date-of-birth') + ' *'
                }
            }
        },
        {
            key: 'city',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.city ? ' field-error' : ''),
                    error: fieldErrors.city,
                    value: fieldsData.city,
                    inputProps: {
                        name: 'city'
                    },
                    helperText: fieldErrors.city || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'city' }),
                    label: getLanguageValue('register-group.stage1-city') + ' *'
                }
            }
        },
        {
            key: 'addressLine',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ADDRESS_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.addressLine ? ' field-error' : ''),
                    error: fieldErrors.addressLine,
                    value: fieldsData.addressLine,
                    inputProps: {
                        name: 'addressLine'
                    },
                    helperText: fieldErrors.addressLine || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'addressLine' }),
                    label: getLanguageValue('register-group.stage1-address-line') + ' *'
                }
            }
        },
        {
            key: 'email',
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.email ? ' field-error' : ''),
                    error: fieldErrors.email,
                    value: fieldsData.email,
                    inputProps: {
                        name: 'email'
                    },
                    helperText: fieldErrors.email || '',
                    onChange: DEFAULT_ON_CHANGE.bind({ trim: true }),
                    onBlur: (e) => {
                        if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.email)) {
                            notifyClientError({ data: fieldsData, errors: { ...fieldErrors, 'email' : (!fieldsData.email.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) } });
                            setFieldErrors({ ...fieldErrors, 'email' : (!fieldsData.email.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) });
                        }
                    },
                    label: getLanguageValue('register-group.stage1-email') + ' *'
                }
            }
        },
        {
            key: 'emailConfirm',
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.emailConfirm ? ' field-error' : ''),
                    error: fieldErrors.emailConfirm,
                    value: fieldsData.emailConfirm,
                    inputProps: {
                        name: 'emailConfirm'
                    },
                    helperText: fieldErrors.emailConfirm || '',
                    onChange: DEFAULT_ON_CHANGE.bind({ trim: true }),
                    onBlur: (e) => {
                        if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.emailConfirm)) {
                            notifyClientError({ data: fieldsData, errors: { ...fieldErrors, 'emailConfirm' : (!fieldsData.emailConfirm.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) } });
                            setFieldErrors({ ...fieldErrors, 'emailConfirm' : (!fieldsData.emailConfirm.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) });
                        }
                    },
                    label: getLanguageValue('register-group.stage1-email-confirm') + ' *'
                }
            }
        }
    ];

    let FIELDS_WITHOUT_EMAIL = FIELDS.filter((item) => ['email', 'emailConfirm'].indexOf(item.key) == -1);
    let EMAIL_FIELDS_ONLY = FIELDS.filter((item) => ['email', 'emailConfirm'].indexOf(item.key) > -1);

    useEffect(() => {
        actions.setActiveStage(1);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FFa-zA-Z\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ADDRESS_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FFa-zA-Z\s0-9,]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        if (!/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, date);
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    function DEFAULT_ON_CHANGE(e) {
        let newValue = e.target.value;
        if (this && this.trim) {
            newValue = newValue.trim();
        }
        setFieldsData({...fieldsData, [e.target.name] : newValue });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    notifyClientError({ data: fieldsData, errors: { ...fieldErrors, [this.key] : validatorObj.errorMessage } });
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    return <RegisterStage1Style>
        <div className="form-container">
            <h1>{getLanguageValue('register-group.stage1-group-contact-information')}</h1>
            <div className="form-information-container">
                <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-group.stage1-group-contact-information-explanation') }} />
                <br />
                <div dangerouslySetInnerHTML={{ __html: getLanguageValue('register-group.stage1-group-contact-information-retrieve-explanation')}} />
                <span className="bold-text field-warning-color">{getLanguageValue('register-group.stage1-required-fields-with-asterisk')}</span>
            </div>
            <hr className={"default-form-hr-style"} />
            <h2>{getLanguageValue('register-group.stage1-group-contact-person-information')}</h2>
            <div>{getLanguageValue('register-group.stage1-group-contact-person-age-notification')}</div>
            <div className={"fields-container"}>
                <Grid container className={"fields-container"}>

                    {FIELDS_WITHOUT_EMAIL.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={6}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}

                </Grid>
            </div>

            <div className={"fields-container"}>
                <Grid container className={"fields-container"}>

                    {EMAIL_FIELDS_ONLY.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={6}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}

                </Grid>
            </div>
            <div className={"buttons-container"}>
                <Button className="general-style-next-button" disabled={isNextDisabled()} onClick={next}>{getLanguageValue('register-common.stages-buttons-next')} {lodashGet(props, 'loaders.next')}</Button>
            </div>
            <div className={"submit-error"}>{globalErrors.submit ? 'test' : ''}</div>
            {registerSubProcessOpened && <RegisterGroupStage1SubProcess actions={registerSubProcessActions} replacements={[ { value: '{email}', newValue: fieldsData.email }]} />}
        </div>
    </RegisterStage1Style>;

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        const validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            notifyClientError({ data: fieldsData, errors: fieldErrors });
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (actions && actions.nextStage) {
            let dataToUpdate = lodashCloneDeep(fieldsData);
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'registerGroupStagesData.stage1Data',
                    value: dataToUpdate
                }]
            });
            setRegisterSubProcessOpened(true);
            setRegisterSubProcessActions({
                onClose: async function(reason) {
                    setRegisterSubProcessOpened(false);
                    if (reason == 'abort') {
                        // nothing to do
                    }
                    else if (reason == 'success') {
                        actions.nextStage();
                    }
                }
            });

            // let duplicateData = {...fieldsData};
            // delete duplicateData.emailConfirm;
            // let isSuccess = false;
            // try {
            //     const fetchResult = await (await fetch(getApiRoot() + 'init-process/?type=group&languageKey=' + props.languageKey,
            //         {
            //             method: 'post',
            //             body: JSON.stringify({ ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage1') }),
            //             headers: {
            //                 'content-type': 'application/json'
            //             }
            //         })).json();
            //     isSuccess = lodashGet(fetchResult, 'data') ? true : false;
            // }
            // catch (err) {
            //     // nothing to do
            // }
            // return;
            // if (isSuccess) {
            //     actions.nextStage();
            // }
            // else {
            //
            // }
        }
    }

    function exit() {
    }

    function validate(config) {
        const errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of FIELDS) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.email)) {
            errors.push('email');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['email'] = !fieldsData.email.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
            }
        }
        if (!new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.emailConfirm)) {
            errors.push('emailConfirm');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['emailConfirm'] = !fieldsData.emailConfirm.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
            }
        }
        else if (new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(fieldsData.email) && fieldsData.email != fieldsData.emailConfirm) {
            errors.push('email');
            if (lodashGet(config, 'setErrors')) {
                fieldErrors['email'] = getLanguageValue('register-field-errors-no-match-to-confirm-field');
                fieldErrors['emailConfirm'] = ' ';
            }
        }

        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
        }
        return errors;
    }
}

const RegisterStage1Style = styled.div`
  padding: 20px 30%;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    padding: 20px 10%;
  }
  .fields-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .select-visa-delivery-type-container {
    width: 100%;
  }
  .select-visa-delivery-explanation-container {
    max-width: 500px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  #deliveryDestination {
    @media (max-width: 550px) {
      font-size: 3vw;
      height: auto;
    }
  }
  .delivery-self-address-container {
    color: ${CSS_COLORS.FIELD_WARNING};
    margin-bottom: 10px;
  }
  .delivery-type-container {
    margin-bottom: 20px;
  }
  .visa-delivery-destination-container {
    margin-top: 10px;
  }
`;

const RegisterStage1 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        stage1Data: lodashGet(state, 'registerGroupStagesData.stage1Data'),
        stage1DataDisclaimer: lodashGet(state, 'registerIndividualStagesData.stage1Data.disclaimer')
    }),
    {})(RegisterStage1Component);

export default RegisterStage1;
