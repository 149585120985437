/* eslint-disable eqeqeq */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ACTIONS } from './redux/actions';
import { createStore, compose, applyMiddleware } from 'redux';
import combinedReducer from './redux/reducers';
import RouterComponent from './components/RouterComponent';
import { googleRecaptcha, getContextRoot, getLanguageValue, getQueryParam, changeLanguage, updateLanguageData } from './common/functions';
import moment from 'moment';
import CONSTANTS from './common/constants';
import lodashGet from 'lodash/get';

let store = createStore(
    combinedReducer,
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

// fix rtl or ltr to HTML tag
var rootHtmlTag = document.getElementsByTagName( 'html' )[0];
try {
    rootHtmlTag.classList.add(window.LANGUAGE.direction);
    rootHtmlTag.setAttribute('data-language', window.LANGUAGE.name);
    //store.dispatch({ type: ACTIONS.SET_LANGUAGE, payload: window.LANGUAGE.key });

    // for debugging
    store.dispatch({ type: ACTIONS.GENERIC_SET_VALUE, payload: [{ path: 'noDataReset', value: window.location.search.indexOf('noDataReset=1') > -1 || localStorage.getItem('noDataReset') == '1' }] });

    // attach quick fetcher for debugging
    window.qPost = async function(url, body) {
        try {
            let result = (await fetch(url, { method: 'post', headers: { 'content-type': 'application/json' }, body: JSON.stringify(body) }));
            result = await result.text();
            try {
                result = JSON.parse(result);
            }
            catch (err) {}
            console.log('result', result);
            return result;
        }
        catch (err) {
        }
    };
    window.qGet = async function(url) {
        try {
            let result = (await fetch(url, { method: 'get', headers: { 'content-type': 'application/json' } }));
            result = await result.text();
            try {
                result = JSON.parse(result);
            }
            catch (err) {}
            console.log('result', result);
            return result;
        }
        catch (err) {
        }
    };
    (function() {
        window.addEventListener('resize', resizeHandler);
        setTimeout(resizeHandler, 1000);
        function resizeHandler() {
            var screenWidth = document.documentElement.clientWidth;
            if (screenWidth > 960) {
                if (rootHtmlTag.classList.contains('mobile')) {
                    rootHtmlTag.classList.remove('mobile');
                }
            }
            else {
                if (!rootHtmlTag.classList.contains('mobile')) {
                    rootHtmlTag.classList.add('mobile');
                }
            }
        }
    })();


    window.googleRecaptcha = googleRecaptcha;
    window.moment = moment;
    window.getLanguageValue = getLanguageValue;
    window.onbeforeunload = function() { if (window.NAVIGATION_WARNING_ENABLED) return (window.LANGUAGE['register-common.warning-in-progress'] || "Are you sure you want to leave this page?"); };
    window.CONSTANTS = CONSTANTS;
    //window.onpopstate = () => {
        //window.history.pushState(null, "", window.location.href);
    //};
    (async function() {
        try {
            // let x = store;
            // let u = CONSTANTS;

            let result = await (await fetch(getContextRoot() + 'api/get-env-data/')).json();
            // add recaptcha
            let script;
            script = document.createElement('script');
            script.src = 'https://www.google.com/recaptcha/api.js?render=' + lodashGet(result, 'data.keys.recaptcha');
            document.head.appendChild(script);
            // add paypal
            script = document.createElement('script');
            script.src = 'https://www.paypal.com/sdk/js?client-id=' + lodashGet(result, 'data.keys.paypal');
            document.head.appendChild(script);
            CONSTANTS.INACTIVE_FIELDS = lodashGet(result, 'data.inactiveFields');
            CONSTANTS.GOOGLE_RECAPTCHA_KEY = lodashGet(result, 'data.keys.recaptcha');
            CONSTANTS.STRIPE_KEY = lodashGet(result, 'data.keys.stripe');
            CONSTANTS.PAYPAL_KEY = lodashGet(result, 'data.keys.paypal');
            CONSTANTS.PRICES = lodashGet(result, 'data.prices');
            CONSTANTS.YOUTUBE_ON_HEADER_ENABLED = lodashGet(result, 'data.config.youtubeOnHeader') || false;
            CONSTANTS.YOUTUBE_LINK = lodashGet(result, 'data.config.youtubeLink') || '';
            CONSTANTS.VISA_READY_HOURS = lodashGet(result, 'data.config.visaReadyHours') || 24;
            CONSTANTS.SUPPORT_PHONE_NUMBER = lodashGet(result, 'data.config.supportPhoneNumber') || '';
            CONSTANTS.SUPPORT_EMAIL = lodashGet(result, 'data.config.supportEmail') || '';
            CONSTANTS.FILESIZE_MAX_LIMIT = lodashGet(result, 'data.config.maxFileSize') || 5800000;
            CONSTANTS.REQUIRED_IMAGES_FOR_MINOR = lodashGet(result, 'data.config.requiredImagesForMinor') || [ 'parentPassport' ];
            CONSTANTS.CROP_TOOL_DIMENSIONS = lodashGet(result, 'data.config.cropToolDimensions') || {"passport":"450x350 320x250","passportFace":"350x450 250x320","birthCertificate":"500x660 320x425","parentPassport":"450x350 320x250"};
            CONSTANTS.LANGUAGE_SELECT_ENABLED = !!lodashGet(result, 'data.config.clientLanguageSelect');
            CONSTANTS.OCR_ENABLED = !!lodashGet(result, 'data.config.ocrEnabled');
            CONSTANTS.WHATSAPP_ICON_ENABLED = !!lodashGet(result, 'data.config.whatsappIconEnabled');
            // refactor structure
            let cropToolKeysList = [ 'passport', 'passportFace', 'birthCertificate', 'parentPassport'];
            for (let cropToolKey of cropToolKeysList) {
                let desktopMobileParts = CONSTANTS.CROP_TOOL_DIMENSIONS[cropToolKey].split(' ');
                CONSTANTS.CROP_TOOL_DIMENSIONS[`${cropToolKey}Desktop`] = { width: desktopMobileParts[0].split('x')[0], height: desktopMobileParts[0].split('x')[1] };
                CONSTANTS.CROP_TOOL_DIMENSIONS[`${cropToolKey}Mobile`] = { width: desktopMobileParts[1].split('x')[0], height: desktopMobileParts[1].split('x')[1] };
                delete CONSTANTS.CROP_TOOL_DIMENSIONS[cropToolKey];
            }
            // check if should change default language
            setTimeout(() => {
                if (['he', 'en', 'hi'].indexOf(getQueryParam('lang')) > -1 && window.LANGUAGE.key != getQueryParam('lang')) {
                    window.LANGUAGE.key = getQueryParam('lang');
                    changeLanguage(window.LANGUAGE.key, function() {
                        updateLanguageData();
                        store.dispatch({ type: ACTIONS.SET_LANGUAGE, payload: window.LANGUAGE.key });
                    });
                }
                else {
                    store.dispatch({ type: ACTIONS.SET_LANGUAGE, payload: window.LANGUAGE.key });
                }
            }, 10);
            let intervalId = setInterval(() => {
                if (window.LANGUAGE['window.title'].indexOf('{') == -1) {
                    clearInterval(intervalId);
                    window.document.title = window.LANGUAGE['window.title'];
                }
            }, 10);
        }
        catch (err) {

        }
    })();
}
catch (err) {
}

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <RouterComponent />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
