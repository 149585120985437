import ReactDOM from 'react-dom';
import React, {useEffect, useState, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {getContextRoot, getLanguageValue} from "../common/functions";
// import Cropper from 'react-easy-crop';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'
import Croppie from 'croppie';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

let ZOOM_INTERVALID = {}; // used for setting up zoom once have min/max values
function CropHandler(props) {
    const {
        imageSrc,
        imageCssSelector,
        dimensions,
        boundaryDimensions,
        controls // contains callback
    } = props;


    controls.restart = function() {
        if (croppieInstance) {
            setCroppieInstance(null);
            setTimeout(() => {
                croppieInstance.destroy();
                setTimeout(() => {
                    restartCroppie();
                }, 10);
            }, 10);
            return;
        }
        restartCroppie();
    };
    const [ croppieInstance, setCroppieInstance ] = useState(null);

    useEffect(() => {
        if (croppieInstance) {
            // add controls below element
            let containerElement = document.querySelector(imageCssSelector + ' + .cr-boundary + .cr-slider-wrap').parentNode;
            // fix zoom to minimum
            if (ZOOM_INTERVALID[imageCssSelector]) {
                clearInterval(ZOOM_INTERVALID[imageCssSelector]);
            }
            ZOOM_INTERVALID[imageCssSelector] = setInterval(() => {
                let sliderElement = document.querySelector(imageCssSelector + ' + .cr-boundary + .cr-slider-wrap .cr-slider');
                if (!sliderElement) {
                    return;
                }
                let sliderMinimumValue = sliderElement.getAttribute('min');
                if (!sliderMinimumValue) {
                    return;
                }
                croppieInstance.setZoom(sliderMinimumValue);
                clearInterval(ZOOM_INTERVALID[imageCssSelector]);
                ZOOM_INTERVALID[imageCssSelector] = null;
            }, 10);
            let newElement = document.createElement('div');
            containerElement.appendChild(newElement);
            ReactDOM.render(
                <CropButtons />,
                newElement
            );

            function CropButtons(props) {

                return <div className={"image-crop-buttons-container"}>
                    <Button className="crop-rotate-right-button" title={getLanguageValue('crop-handler-rotate-right')} onClick={() => { croppieInstance.rotate(-90); }}><RotateRightIcon /></Button>
                    <Button className="crop-rotate-left-button" title={getLanguageValue('crop-handler-rotate-left')} onClick={() => { croppieInstance.rotate(90);  }}><RotateLeftIcon /></Button>
                    <Button className="crop-finish-button" title={getLanguageValue('crop-handler-finish')} onClick={() => {
                        croppieInstance.result({ type: 'base64', size: 'original' }).then(function(resultValue) {
                            setTimeout(() => {
                                croppieInstance.destroy();
                            }, 10);
                            setCroppieInstance(null);
                            controls.callback(resultValue);
                        });
                    }}><CheckCircleIcon/></Button>
                    <Button className="crop-cancel-button" title={getLanguageValue('crop-handler-cancel-edit')} onClick={() => {
                        setTimeout(() => {
                            croppieInstance.destroy();
                        }, 10);
                        setCroppieInstance(null);
                        controls.callback(false);
                    }}><CancelIcon/></Button>
                </div>;
            }
            return () => {
                ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(newElement));
                setTimeout(() => {
                    let imageEl = document.querySelector(imageCssSelector);
                    if (!imageEl) {
                        return;
                    }
                    let parentEl = imageEl.parentNode;
                    if (!parentEl) {
                        return;
                    }
                    parentEl.removeChild(imageEl);
                    parentEl.parentNode.appendChild(imageEl);
                    parentEl.parentNode.removeChild(parentEl);
                }, 10);
            };
        }
    }, [croppieInstance]);

    function restartCroppie() {
        let boundaryDimensionsLocal = boundaryDimensions;
        if (dimensions && !boundaryDimensions) {
            boundaryDimensionsLocal = {
                width: dimensions.width * 1.2,
                height: dimensions.height * 1.2
            };
        }
        setCroppieInstance(new Croppie(document.querySelector(imageCssSelector), {
            enableOrientation: true,
            viewport: dimensions || {
                width: 500,
                height: 350
            },
            boundary: boundaryDimensionsLocal || {}
        }));
    }

    return null;
}

// function CropHandler(props) {
//     const {
//         imageSrc,
//         controls // contains callback
//     } = props;
//     const [crop, setCrop] = useState({ x: 0, y: 0 });
//     const [rotation, setRotation] = useState(0);
//     const [zoom, setZoom] = useState(1);
//     const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//
//     useEffect(() => {
//         // init once
//
//     }, []);
//
//     return <>
//         <CropHandlerStyle className={'container' + (props.className ? (' ' + props.className) : '')}>
//             <div className={"image-crop-container"}>
//                 <Cropper
//
//                     image={imageSrc}
//                     crop={crop}
//                     rotation={rotation}
//                     zoom={zoom}
//                     aspect={4/3}
//                     onCropChange={setCrop}
//                     onRotationChange={setRotation}
//                     onCropComplete={(croppedArea, croppedAreaPixels) => {
//                         setCroppedAreaPixels(croppedAreaPixels);
//                     }}
//                     onZoomChange={setZoom}
//                 />
//             </div>
//             <div className={"image-crop-buttons-container"}>
//                 <Button onClick={() => { rotate('right'); }}>{getLanguageValue('crop-handler-rotate-right')}</Button>
//                 <Button onClick={() => { rotate('left'); }}>{getLanguageValue('crop-handler-rotate-left')}</Button>
//                 <Button onClick={() => { finish(); }}>{getLanguageValue('crop-handler-finish')}</Button>
//                 <Button onClick={() => { cancelEdit(); }}>{getLanguageValue('crop-handler-cancel-edit')}</Button>
//             </div>
//         </CropHandlerStyle>
//     </>;
//
//     function rotate(direction) {
//         setRotation(rotation + (direction === 'right' ? 90 : -90))
//     }
//
//     async function finish() {
//         if (controls && controls.callback) {
//             let result = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
//             controls.callback(result);
//             // reset config
//             setZoom(1);
//             setRotation(0);
//             setCrop({ x: 0, y: 0});
//             setCroppedAreaPixels(null);
//         }
//     }
//
//     function cancelEdit() {
//         controls.callback(false);
//     }
//
//     function createImage(url) {
//         return new Promise((resolve, reject) => {
//             const image = new Image();
//             image.addEventListener('load', () => resolve(image));
//             image.addEventListener('error', (error) => reject(error));
//             image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
//             image.src = url;
//         });
//     }
//
//     function getRadianAngle(degreeValue) {
//         return (degreeValue * Math.PI) / 180;
//     }
//
//     async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
//         const image = await createImage(imageSrc);
//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//
//         const maxSize = Math.max(image.width, image.height);
//         const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
//
//         // set each dimensions to double largest dimension to allow for a safe area for the
//         // image to rotate in without being clipped by canvas context
//         canvas.width = safeArea;
//         canvas.height = safeArea;
//
//         // translate canvas context to a central location on image to allow rotating around the center.
//         ctx.translate(safeArea / 2, safeArea / 2);
//         ctx.rotate(getRadianAngle(rotation));
//         ctx.translate(-safeArea / 2, -safeArea / 2);
//
//         // draw rotated image and store data.
//         ctx.drawImage(
//             image,
//             safeArea / 2 - image.width * 0.5,
//             safeArea / 2 - image.height * 0.5
//         );
//         const data = ctx.getImageData(0, 0, safeArea, safeArea);
//
//         // set canvas width to final desired crop size - this will clear existing context
//         canvas.width = pixelCrop.width;
//         canvas.height = pixelCrop.height;
//
//         // paste generated rotate image with correct offsets for x,y crop values.
//         ctx.putImageData(
//             data,
//             Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//             Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//         );
//
//         // As Base64 string
//         return canvas.toDataURL('image/jpeg');
//
//         // As a blob
//         // return new Promise((resolve) => {
//         //     canvas.toBlob((file) => {
//         //         resolve(URL.createObjectURL(file))
//         //     }, 'image/jpeg');
//         // });
//     }
// }

const CropHandlerStyle = styled.div`
  width: 500px;
  height: 500px;
  //.image-crop-container {
  //  border: 1px solid black;
  //  position: relative;
  //  width: 100%;
  //  height: 350px;
  //  background: #333;
  //}
  //button {
  //  border: 1px solid black;
  //  margin-top: 10px;
  //  margin-left: 10px;
  //}
`;

export default CropHandler;