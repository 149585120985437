import React from 'react';
import CONSTANTS from './constants';
import lodashGet from 'lodash/get';
import { ACTIONS } from "../redux/actions";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CancelIcon from '@material-ui/icons/Cancel';
import fileType from 'file-type';
import moment from "moment";

function getQueryParam(name) {
    if (window.location.search) {
        try {
            let queryParams = window.location.search.substring(1).split('&');
            for (let queryParam of queryParams) {
                let queryParamParts = queryParam.split('=');
                if (queryParamParts[0] == name) {
                    return queryParamParts[1];
                }
            }
        }
        catch (err) {
            // nothing to do
        }
    }
    return '';
}

function getLanguageValue(languageKey) {
    return window.LANGUAGE[languageKey] || ('__' + languageKey + '__');
}

function getHelpTopics() {
    return lodashGet(window, 'HELP.topics') || [];
}

function getFooterData() {
    return lodashGet(window, 'FOOTER.columns') || [];
}

function getContextRoot() {
    return CONSTANTS.CONTEXT_ROOT;
}

function getApiRoot() {
    return CONSTANTS.API_ROOT;
}

async function changeLanguage(languageKey, callback) {
    try {
        let result = await fetch(getContextRoot() + 'lang/lang.' + languageKey + '.js');
        result = await result.text();
        eval(result); // eslint-disable-line
        result = await fetch(getContextRoot() + 'lang/help.' + languageKey + '.js');
        result = await result.text();
        eval(result); // eslint-disable-line
        result = await fetch(getContextRoot() + 'lang/footer.' + languageKey + '.js');
        result = await result.text();
        eval(result); // eslint-disable-line
        if (typeof(callback) == 'function') {
            callback(true, languageKey);
        }
        return;
    }
    catch (err) {
        // nothing to do
    }
    if (typeof(callback) == 'function') {
        callback(false, languageKey);
    }
}

function updateLanguageData() {
    let rootHtmlTag = document.getElementsByTagName( 'html' )[0];
    try {
        rootHtmlTag.classList.remove('rtl');
        rootHtmlTag.classList.remove('ltr');
        rootHtmlTag.classList.add(window.LANGUAGE.direction);
        rootHtmlTag.setAttribute('data-language', window.LANGUAGE.name);
        window.document.title = window.LANGUAGE['window.title'];
    }
    catch (err) {
    }
}

function isElementInView(el) {
    if (!el) {
        return false;
    }
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible;

    // Only completely visible elements return true:
    //isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

    // Partially visible elements return true:
    isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

function changeFieldsWithPrefixAndCamelCase(fieldsObject, prefix, excludedFields) {
    let output = {};
    for (let fieldKey in fieldsObject) {
        if (!excludedFields || excludedFields.indexOf(fieldKey) == -1) {
            let newFieldKey = prefix + fieldKey.substring(0,1 ).toUpperCase() + fieldKey.substring(1);
            output[newFieldKey] = fieldsObject[fieldKey] !== null ? fieldsObject[fieldKey] : '';
        }
    }
    return output;
}

async function sleep(time) {
    await new Promise(function(resolve, reject) {
        setTimeout(function() {
            resolve();
        }, time);
    });
}

async function googleRecaptcha() {
    return new Promise(function(resolve, reject) {
        if (!window.grecaptcha || !CONSTANTS.GOOGLE_RECAPTCHA_KEY) {
            let intervalId = setInterval(() => {
                if (window.grecaptcha && CONSTANTS.GOOGLE_RECAPTCHA_KEY) {
                    clearInterval(intervalId);
                    setTimeout(executeRecaptcha, 1000);
                }
            }, 30);
        }
        else {
            executeRecaptcha();
        }
        function executeRecaptcha() {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(CONSTANTS.GOOGLE_RECAPTCHA_KEY, { action: 'submit' }).then(function (token) {
                    resolve(token);
                });
            });
        }
    });
}

function navigateToPath(dispatch, path) {
    dispatch({
        type: ACTIONS.ROUTER_SET_REDIRECT,
        payload: path
    });
}

function translateCardDeclineReason(resultObject) {
    let value = lodashGet(resultObject, 'error.code');
    if (value) {
        switch (value) {
            case 'card_declined':
                return getLanguageValue('register-card-errors-declined');
            case 'expired_card':
                return getLanguageValue('register-card-errors-expired');
            case 'incorrect_number':
                return getLanguageValue('register-card-errors-invalid-card-number');
            case 'incorrect_cvc':
                return getLanguageValue('register-card-errors-invalid-cvc');
            case 'invalid_expiry_month':
            case 'invalid_expiry_year':
                return getLanguageValue('register-card-errors-invalid-expiry');
            case 'insufficient_funds':
                return getLanguageValue('register-card-errors-invalid-insufficient-funds');
            default:
                return getLanguageValue('register-card-errors-invalid-generic');
        }
    }
    return getLanguageValue('register-card-errors-invalid-generic');
}

function resetRegisterIndividualStagesData(dispatch, currentState) {
    if (currentState && currentState.noDataReset) {
        return;
    }
    dispatch({
        type: ACTIONS.GENERIC_SET_VALUE,
        payload: [{
            path: 'registerIndividualStagesData',
            value: {
                stage1Data: {},
                stage2Data: {},
                stage3Data: {},
                stage4Data: {},
                stage5Data: {},
                stage6Data: {},
                stage7Data: {}, // finish app
                stagePayData: {}
            }
        }]
    });
}

function resetRegisterGroupStagesData(dispatch, currentState) {
    if (currentState && currentState.noDataReset) {
        return;
    }
    dispatch({
        type: ACTIONS.GENERIC_SET_VALUE,
        payload: [{
            path: 'registerGroupStagesData',
            value: {
                stage1Data: {},
                stage2Data: {}
            }
        }]
    });
}

function resetRetrieveIndividualData(dispatch, currentState) {
    if (currentState && currentState.noDataReset) {
        return;
    }

    dispatch({
        type: ACTIONS.GENERIC_SET_VALUE,
        payload: [
            {
                path: 'retrieveIndividualApplication',
                value: {
                    key: '',
                    passportNumber: '',
                    birthDate: '',
                }
            },
            {
                path: 'globalControls',
                value: {
                    refreshGroupData: false,
                    isIndividualLinkedToGroup: false,
                    isRetrieveLinkedToGroup: false
                }
            }]
    });
}

function resetRetrieveGroupData(dispatch, currentState) {
    if (currentState && currentState.noDataReset) {
        return;
    }
    dispatch({
        type: ACTIONS.GENERIC_SET_VALUE,
        payload: [
            {
                path: 'retrieveGroupApplication',
                value: {
                    key: '',
                    lastName: '',
                    firstName: '',
                    email: '',
                    birthDate: ''
                }
            },
            {
                path: 'globalControls',
                value: {
                    refreshGroupData: false,
                    isIndividualLinkedToGroup: false,
                    isRetrieveLinkedToGroup: false
                }
            }]
    });
}

function genericGetListFromLanguage(keysString, valuesString, keyField, valueField) {
    let output = [];
    const keys = getLanguageValue(keysString).split(',');
    const values = getLanguageValue(valuesString).split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ [keyField]: keys[loop], [valueField]: values[loop] });
    }
    return output;
}

function getVisaCountriesList() {
    let output = [];
    const keys = getLanguageValue('register-common-visa-countries-keys-array').split(',');
    const values = getLanguageValue('register-common-visa-countries-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getCountriesList() {
    let output = [];
    const keys = getLanguageValue('register-common-countries-keys-array').split(',');
    const values = getLanguageValue('register-common-countries-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getCitiesList() {
    let output = [];
    const keys = getLanguageValue('register-common-israel-cities-keys-array').split(',');
    const values = getLanguageValue('register-common-israel-cities-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getPhoneExtensionsList() {
    let output = [];
    const keys = getLanguageValue('register-common-phone-extensions-keys-array').split(',');
    const values = getLanguageValue('register-common-phone-extensions-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getMaritalStatusList() {
    let output = [];
    const keys = getLanguageValue('register-individual.stage3-marital-status-keys-array').split(',');
    const values = getLanguageValue('register-individual.stage3-marital-status-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getEducationList() {
    let output = [];
    const keys = getLanguageValue('register-individual.stage3-education-keys-array').split(',');
    const values = getLanguageValue('register-individual.stage3-education-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getProfessionList() {
    let output = [];
    const keys = getLanguageValue('register-individual.stage3-profession-keys-array').split(',');
    const values = getLanguageValue('register-individual.stage3-profession-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getBorderOfEntryList() {
    let output = [];
    const keys = getLanguageValue('register-individual.stage2-select-border-of-entry-keys-array').split(',');
    const values = getLanguageValue('register-individual.stage2-select-border-of-entry-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getPurposeOfTravelList() {
    let output = [];
    const keys = getLanguageValue('register-individual.stage2-select-purpose-of-travel-keys-array').split(',');
    const values = getLanguageValue('register-individual.stage2-select-purpose-of-travel-values-array').split(',');
    for (let loop = 0 ; loop < keys.length && loop < values.length ; ++loop) {
        output.push({ key: keys[loop], value: values[loop] });
    }
    return output;
}

function getStatusHtml(item) {

    let fullCircleArrayCount = [];
    let emptyCircleArrayCount = [];
    let rejectCircleArrayCount= [];
    let statusValue = item.isExpired ? 'expired' : item.status;
    let rejectWithReasonsFlag = false;
    if (item.rejectReasons && Object.keys(item.rejectReasons).length) {
        rejectWithReasonsFlag = true;
    }
    switch (statusValue) {
        case 'paid':
            fullCircleArrayCount = [1,1];
            emptyCircleArrayCount = [1,1];
            break;
        case 'waiting-for-agent':
            fullCircleArrayCount = [1,1,1];
            emptyCircleArrayCount = [1];
            break;
        case 'ready':
        case 'ready-without-files':
            fullCircleArrayCount = [1,1,1,1];
            emptyCircleArrayCount = [];
            break;
        case 'reject':
            fullCircleArrayCount = [1,1,1];
            emptyCircleArrayCount = [];
            rejectCircleArrayCount = [1];
            break;
        default:
            fullCircleArrayCount = [1];
            emptyCircleArrayCount = [1,1,1];
            break;
    }
    return <>
        <span className={"form-status " + statusValue}>{getLanguageValue('register-application-status-' + statusValue + (rejectWithReasonsFlag ? '-with-reasons' : ''))}</span>
        <br className="status-br"/>
        <span className={"progress-circles-container"}>
            {fullCircleArrayCount.map((cItem, cItemIndex) => (<FiberManualRecordIcon key={cItemIndex} className={"circle-component"} />))}
            {emptyCircleArrayCount.map((cItem, cItemIndex) => (<RadioButtonUncheckedIcon key={cItemIndex} className={"circle-component"} />))}
            {rejectCircleArrayCount.map((cItem, cItemIndex) => (<CancelIcon key={cItemIndex} className={"reject-circle-component"} />))}
        </span>
    </>;
}

function scrollToElement(cssSelector) {
    let element = document.querySelector(cssSelector);
    if (element) {
        window.scrollTo(0, element.getClientRects()[0].y + window.scrollY);
    }
}

function updateLanguageWithConstants() {
    let keys = Object.keys(window.LANGUAGE);
    for (let key of keys) {
        if (typeof(window.LANGUAGE[key]) == 'string') {
            window.LANGUAGE[key] = window.LANGUAGE[key]
                .replace(new RegExp('{PRICES_30_DAYS_SINGLE}', 'g'), lodashGet(CONSTANTS, 'PRICES.30days_single'))
                .replace(new RegExp('{PRICES_30_DAYS_MULTI}', 'g'), lodashGet(CONSTANTS, 'PRICES.30days_multi'))
                .replace(new RegExp('{PRICES_90_DAYS_SINGLE}', 'g'), lodashGet(CONSTANTS, 'PRICES.90days_single'))
                .replace(new RegExp('{PRICES_90_DAYS_MULTI}', 'g'), lodashGet(CONSTANTS, 'PRICES.90days_multi'))
                .replace(new RegExp('{PRICES_EXPEDITE}', 'g'), lodashGet(CONSTANTS, 'PRICES.expedite'))
                .replace(new RegExp('{VISA_READY_HOURS}', 'g'), lodashGet(CONSTANTS, 'VISA_READY_HOURS'))
                .replace(new RegExp('{SUPPORT_PHONE_NUMBER}', 'g'), CONSTANTS.SUPPORT_PHONE_NUMBER)
                .replace(new RegExp('{SUPPORT_EMAIL}', 'g'), CONSTANTS.SUPPORT_EMAIL)
                .replace(new RegExp('{DATE_FORMAT}', 'g'), CONSTANTS.DATE_FORMAT_DATEPICKER.toLowerCase());
        }
    }
    if (window.HELP.topics) {
        for (let item of window.HELP.topics) {
            let title = item.title;
            let subtopics = item.subTopics;
             for (let subtopicItem of subtopics) {
                 subtopicItem.fullHtml = subtopicItem.fullHtml
                     .replace(new RegExp('{PRICES_30_DAYS_SINGLE}', 'g'), lodashGet(CONSTANTS, 'PRICES.30days_single'))
                     .replace(new RegExp('{PRICES_30_DAYS_MULTI}', 'g'), lodashGet(CONSTANTS, 'PRICES.30days_multi'))
                     .replace(new RegExp('{PRICES_90_DAYS_SINGLE}', 'g'), lodashGet(CONSTANTS, 'PRICES.90days_single'))
                     .replace(new RegExp('{PRICES_90_DAYS_MULTI}', 'g'), lodashGet(CONSTANTS, 'PRICES.90days_multi'))
                     .replace(new RegExp('{PRICES_EXPEDITE}', 'g'), lodashGet(CONSTANTS, 'PRICES.expedite'))
                     .replace(new RegExp('{VISA_READY_HOURS}', 'g'), lodashGet(CONSTANTS, 'VISA_READY_HOURS'))
                     .replace(new RegExp('{SUPPORT_PHONE_NUMBER}', 'g'), CONSTANTS.SUPPORT_PHONE_NUMBER)
                     .replace(new RegExp('{SUPPORT_EMAIL}', 'g'), CONSTANTS.SUPPORT_EMAIL);
             }
        }
    }
    if (window.FOOTER && window.FOOTER.columns) {
        for (let item of window.FOOTER.columns) {
            item.title = item.title
                .replace(new RegExp('{SUPPORT_PHONE_NUMBER}', 'g'), CONSTANTS.SUPPORT_PHONE_NUMBER)
                .replace(new RegExp('{SUPPORT_EMAIL}', 'g'), CONSTANTS.SUPPORT_EMAIL);
            item.html = item.html
                .replace(new RegExp('{SUPPORT_PHONE_NUMBER}', 'g'), CONSTANTS.SUPPORT_PHONE_NUMBER)
                .replace(new RegExp('{SUPPORT_EMAIL}', 'g'), CONSTANTS.SUPPORT_EMAIL);
        }
    }
}

function sortArrayByKey(arr, key) {
    for (let loop1 = 0 ; loop1 < arr.length ; ++loop1) {
        for (let loop2 = 0 ; loop2 < arr.length ; ++loop2) {
            let item1 = arr[loop1];
            let item2 = arr[loop2];
            if (item1[key] < item2[key]) {
                arr[loop1] = item2;
                arr[loop2] = item1;
            }
        }
    }
    return arr;
}

function getExtensionByFileContent(fileContent) {
    try {
        return fileContent.split(';')[0].split('/')[1];
    }
    catch (err) {
        return 'jpg'; //default
    }
}

function convertBase64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

function getFileType(fileContentBase64) {
    return new Promise(async function(resolve, reject) {
        try {
            if (fileContentBase64.indexOf(',') > -1) {
                fileContentBase64 = fileContentBase64.split(',')[1];
            }
            let asArrayBuffer = convertBase64ToArrayBuffer(fileContentBase64);
            let fileTypeResult = await fileType.fromBuffer(asArrayBuffer);
            resolve(fileTypeResult);
        }
        catch (err) {
            reject(err);
        }
    });
}

async function executeSingleRequest() {
    const {
        url,
        requestBody
    } = this;

    let recaptchaToken = await googleRecaptcha();
    let options = {
        ...CONSTANTS.POST_DEFAULT_OPTIONS,
        body: JSON.stringify({
            recaptchaToken,
            ...requestBody
        })
    };
    try {
        // save the file
        let result = await (await fetch(url, options)).json();
        return result;
    }
    catch (err) {
        // nothing to do
        return false;
    }
}

function isVisaReady(visaStatus) {
    return (visaStatus == 'ready' || visaStatus == 'ready-without-files');
}

function isInactiveField(stage, fieldName) {
    return ((CONSTANTS.INACTIVE_FIELDS[stage] ? CONSTANTS.INACTIVE_FIELDS[stage] : []).indexOf(fieldName) > -1);
}

function splitArrayIntoChunks(array, chunkSize) {
    var chunks = [];
    let singleChunk = [];
    for (let loop = 0 ; loop < array.length ; ++loop) {
        singleChunk.push(array[loop]);
        if (singleChunk.length >= chunkSize) {
            chunks.push(singleChunk);
            singleChunk = [];
        }
    }
    if (singleChunk.length) {
        chunks.push(singleChunk);
    }
    return chunks;
}

function isOneOfSystemProfiles(list) {
    let currentSystemProfile = document.getElementsByTagName( 'html' )[0].getAttribute('data-profile');
    return (list.indexOf(currentSystemProfile) > -1);
}

function getDiscountPercentage(formsCount, prices) {
    let discountPercentage = 1;
    let lastMatchOfMinFormsCount = -1;
    if (prices?.discount) {
        let discountOptions = prices.discount.split(',');
        for (let discountOption of discountOptions) {
            let discountMinFormsCount = parseInt(discountOption.split(' ')[0]);
            if ((lastMatchOfMinFormsCount == -1 || lastMatchOfMinFormsCount < discountMinFormsCount) && formsCount >= discountMinFormsCount) {
                // use this discount
                discountPercentage = (100 - parseFloat(discountOption.split(' ')[1])) / 100;
                lastMatchOfMinFormsCount = discountMinFormsCount;
            }
        }
    }
    // no discount
    return discountPercentage;
}

async function notifyClientError(obj) {
    try {
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({ ...obj, recaptchaToken })
        };
        await fetch(getApiRoot() + 'notify-client-error/', options);
    }
    catch (err) {
        // nothing to do
    }
}

function convertDateFieldsIntoString(obj, dateDisplayFormat) {
    for (let key in obj) {
        if (obj[key] instanceof Date) {
            obj[key] = moment(obj[key]).format(dateDisplayFormat);
        }
    }
}

export {
    getQueryParam,
    getLanguageValue,
    getHelpTopics,
    getFooterData,
    getContextRoot,
    getApiRoot,
    changeLanguage,
    updateLanguageData,
    isElementInView,
    changeFieldsWithPrefixAndCamelCase,
    sleep,
    googleRecaptcha,
    navigateToPath,
    translateCardDeclineReason,
    resetRegisterIndividualStagesData,
    resetRegisterGroupStagesData,
    resetRetrieveIndividualData,
    resetRetrieveGroupData,
    genericGetListFromLanguage,
    getVisaCountriesList,
    getCountriesList,
    getCitiesList,
    getPhoneExtensionsList,
    getMaritalStatusList,
    getEducationList,
    getProfessionList,
    getBorderOfEntryList,
    getPurposeOfTravelList,
    getStatusHtml,
    scrollToElement,
    updateLanguageWithConstants,
    sortArrayByKey,
    getExtensionByFileContent,
    convertBase64ToArrayBuffer,
    getFileType,
    executeSingleRequest,
    isVisaReady,
    isInactiveField,
    splitArrayIntoChunks,
    isOneOfSystemProfiles,
    getDiscountPercentage,
    notifyClientError,
    convertDateFieldsIntoString
};
