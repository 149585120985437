import React, { useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import { changeFieldsWithPrefixAndCamelCase, getApiRoot, getContextRoot, getLanguageValue, navigateToPath, googleRecaptcha, getCountriesList, getCitiesList, getPhoneExtensionsList, getMaritalStatusList, getEducationList, getProfessionList, isInactiveField } from "../../common/functions";
import { stringValueValidator } from '../../common/validators';
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import GenericFieldComponent from '../GenericFieldComponent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CONSTANTS from '../../common/constants';
import { ACTIONS } from '../../redux/actions';
import {CSS_COLORS} from "../../common/cssColors";


function RegisterStage3Component(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    let initialStateObject = {
        addressLine1: '',
        addressLine2: '',
        apartmentNumber: '',
        city: '',
        stateOrProvince: '',
        country: props.languageKey != 'he' ? 'select' : 'Israel',
        maritalStatus: 'select',
        education: 'select',
        profession: 'select',
        phones: [
            {
                telephoneType: 'select',
                countryCode: props.languageKey != 'he' ? 'select' : '+972',
                phoneNumber: '',
                errors: {} // errors
            }
        ],
        // override with props data
        ...props.stage3Data
    };
    if (!initialStateObject.country) {
        initialStateObject.country = (props.languageKey != 'he' ? 'select' : 'Israel');
    }
    if (!initialStateObject.maritalStatus) {
        initialStateObject.maritalStatus = 'select';
    }
    if (!initialStateObject.education) {
        initialStateObject.education = 'select';
    }
    if (!initialStateObject.profession) {
        initialStateObject.profession = 'select';
    }
    if (!lodashGet(initialStateObject, 'phones.length')) {
        initialStateObject.phones = [{
            telephoneType: 'select',
            countryCode: props.languageKey != 'he' ? 'select' : '+972',
            phoneNumber: '',
            errors: {} // errors
        }];
    }

    const [ fieldsData, setFieldsData ] = useState(initialStateObject);
    const [ loaders, setLoaders ] = useState({});
    let [ fieldErrors, setFieldErrors ] = useState({ });

    let FIELDS = [
        {
            key: 'addressLine1',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => { return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.addressLine1 ? ' field-error' : ''),
                    error: fieldErrors.addressLine1,
                    value: fieldsData.addressLine1,
                    inputProps: {
                        name: 'addressLine1'
                    },
                    helperText: fieldErrors.addressLine1 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'addressLine1'}),
                    label: getLanguageValue('register-individual.stage3-address-line1') + ' *'
                }
            }
        },
        {
            key: 'addressLine2',
            validators: [
                {
                    test: (value) => { if (!value) return true; return stringValueValidator.bind({ languageKey: 'enAndNumeric' })(value); },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'addressLine2 field-component' + (fieldErrors.addressLine2 ? ' field-error' : ''),
                    error: fieldErrors.addressLine2,
                    value: fieldsData.addressLine2,
                    inputProps: {
                        name: 'addressLine2'
                    },
                    helperText: fieldErrors.addressLine2 || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'addressLine2'}),
                    label: getLanguageValue('register-individual.stage3-address-line2')
                }
            }
        },
        {
            key: 'apartmentNumber',
            validators: [
                {
                    test: DEFAULT_NUMERIC_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.apartmentNumber ? ' field-error' : ''),
                    error: fieldErrors.apartmentNumber,
                    value: fieldsData.apartmentNumber,
                    inputProps: {
                        name: 'apartmentNumber'
                    },
                    helperText: fieldErrors.apartmentNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'apartmentNumber'}),
                    label: getLanguageValue('register-individual.stage3-apartment-number')
                }
            }
        },
        {
            key: 'country',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'country',
                selectLabel: getLanguageValue('register-individual.stage3-country') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getCountriesList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.country ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.country,
                    value: fieldsData.country,
                    inputProps: {
                        name: 'country',
                        id: 'country'
                    },
                    helperText: fieldErrors.country || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        // {
        //     key: 'stateOrProvince',
        //     validators: [
        //         {
        //             test: DEFAULT_HEB_STRING_VALUE_VALIDATOR,
        //             errorMessage: getLanguageValue('register-field-errors-invalid')
        //         }
        //     ],
        //     props: {
        //         type: 'textfield',
        //         fieldProps: {
        //             className: 'field-component' + (fieldErrors.stateOrProvince ? ' field-error' : ''),
        //             error: fieldErrors.stateOrProvince,
        //             value: fieldsData.stateOrProvince,
        //             inputProps: {
        //                 name: 'stateOrProvince'
        //             },
        //             helperText: fieldErrors.stateOrProvince || '',
        //             onChange: DEFAULT_ON_CHANGE,
        //             onBlur: DEFAULT_ON_BLUR.bind({ key: 'stateOrProvince'}),
        //             label: getLanguageValue('register-individual.stage3-state-or-province')
        //         }
        //     }
        // },
        {
            key: 'city',
            validators: [
                {
                    test: (value) => {
                        if (!value) {
                            return false;
                        }
                        return true;
                    },
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_ENG_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    className: 'field-component' + (fieldErrors.city ? ' field-error' : ''),
                    error: fieldErrors.city,
                    value: fieldsData.city,
                    inputProps: {
                        name: 'city'
                    },
                    helperText: fieldErrors.city || '',
                    onChange: DEFAULT_ON_CHANGE,
                    onBlur: DEFAULT_ON_BLUR.bind({ key: 'city'}),
                    label: getLanguageValue('register-individual.stage3-city') + ' *'
                }
            }
        },
        {
            key: 'maritalStatus',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'maritalStatus',
                selectLabel: getLanguageValue('register-individual.stage3-marital-status') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getMaritalStatusList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.maritalStatus ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.maritalStatus,
                    value: fieldsData.maritalStatus,
                    inputProps: {
                        name: 'maritalStatus',
                        id: 'maritalStatus'
                    },
                    helperText: fieldErrors.maritalStatus || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'education',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'education',
                selectLabel: getLanguageValue('register-individual.stage3-education') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getEducationList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.education ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.education,
                    value: fieldsData.education,
                    inputProps: {
                        name: 'education',
                        id: 'education'
                    },
                    helperText: fieldErrors.education || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        },
        {
            key: 'profession',
            validators: [
                {
                    test: (value) => { if (value == 'select') return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-must-select')
                }
            ],
            props: {
                type: 'select',
                selectId: 'profession',
                selectLabel: getLanguageValue('register-individual.stage3-profession') + ' *',
                selectOptions: [
                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                    ...(getProfessionList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                ],
                selectFormClassName: 'field-component' + (fieldErrors.profession ? ' field-error' : ''),
                fieldProps: {
                    native: true,
                    error: fieldErrors.profession,
                    value: fieldsData.profession,
                    inputProps: {
                        name: 'profession',
                        id: 'profession'
                    },
                    helperText: fieldErrors.profession || '',
                    onChange: DEFAULT_ON_CHANGE
                }
            }
        }
    ];

    // filter relevant only
    FIELDS = FIELDS.filter(item => !isInactiveField('stage3', item.key));

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_ENG_HEB_STRING_VALUE_VALIDATOR(value) {
        if (!DEFAULT_ENG_STRING_VALUE_VALIDATOR(value)) {
            if (!DEFAULT_HEB_STRING_VALUE_VALIDATOR(value)) {
                return false;
            }
        }
        return true;
    }

    function DEFAULT_ENG_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([a-zA-Z\"\'\.\-,\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_HEB_STRING_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (!/^([\u0590-\u05FF\"\'\.\-,\s]*)$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_NUMERIC_VALUE_VALIDATOR(value) {
        value = (value ? value.trim() : value);
        if (value && !/^[0-9]+$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_ON_BLUR() {
        let matchedField = FIELDS.find((item) => item.key == this.key);
        if (lodashGet(matchedField, 'validators')) {
            let fieldValue = lodashGet(matchedField, 'props.fieldProps.value');
            for (let validatorObj of lodashGet(matchedField, 'validators')) {
                if (!validatorObj.test(fieldValue)) {
                    setFieldErrors({ ...fieldErrors, [this.key] : validatorObj.errorMessage });
                    return;
                }
            }
        }
    }

    const addPhoneButton = <div className="add-button-container">
        <Button
            disabled={!areAllPhoneValuesOk()}
            onClick={() => {
                fieldsData.phones.push({
                    telephoneType: 'select',
                    countryCode: props.languageKey != 'he' ? 'select' : '+972',
                    phoneNumber: '',
                    errors: {}
                });
                setFieldsData({ ...fieldsData });
            }}>
            <AddCircleIcon />{getLanguageValue('register-individual.stage3-add-another')}
        </Button>
    </div>;

    const deletePhoneButton = (props) => (<div className="delete-button-container">
        <Button onClick={(e) => {
            if (props.itemIndex != null) {
                fieldsData.phones.splice(props.itemIndex, 1);
                setFieldsData({ ...fieldsData });
            }
        }}>
            <RemoveCircleIcon />{getLanguageValue('register-individual.stage3-remove')}
        </Button>
    </div>);

    useEffect(() => {
        actions.setActiveStage(3);
    }, []);

    return <RegisterStage3Style>
        <div className={"form-container"}>
            <h1>{getLanguageValue('register-individual.stage3-enter-contact-information')}</h1>
            <div className="form-information-container">
                <span className="bold-text important-text">{getLanguageValue('register-individual.stage3-provide-responses-in-language')}</span>
                <br/>
                <span className="bold-text field-warning-color">{getLanguageValue('register-individual.stage3-required-fields-with-asterisk')}</span>
            </div>
            <h2>
                {getLanguageValue('register-individual.stage3-personal-information')}
            </h2>
            <Grid item className={"form-fields-container"} md={12}>
                <Grid container className={"fields-container"}>
                    {FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={4}>
                        <GenericFieldComponent {...fieldItem.props} />
                    </Grid>))}
                </Grid>
            </Grid>
            <div className={"phones-container"}>
                {fieldsData.phones.map(function(phoneItem, phoneItemIndex) {
                    return <Grid container className={'phones-list-item-row' + (phoneItemIndex % 2 == 0 ? ' even' : ' odd')} key={phoneItemIndex}>
                        <Grid item className={"single-field-container"} md={4}>
                            <GenericFieldComponent
                                type={"select"}
                                selectLabel={getLanguageValue('register-individual.stage3-phone-type') + ' *'}
                                selectId={'telephoneType' + phoneItemIndex}
                                selectOptions={[
                                    { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage3-select') },
                                    { value: 'home', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-home') },
                                    { value: 'work', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-work') },
                                    { value: 'mobile', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-mobile') },
                                    { value: 'other', isDisabled: false, displayText: getLanguageValue('register-individual.stage3-phone-type-other') }
                                ]}
                                selectFormClassName={'field-component'}
                                fieldProps={{
                                    native: true,
                                    error: lodashGet(phoneItem, 'errors.telephoneType'),
                                    value: phoneItem.telephoneType,
                                    inputProps: {
                                        name: 'telephoneType',
                                        id: 'telephoneType' + phoneItemIndex
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.telephoneType') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], telephoneType: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, telephoneType: false } }; setFieldsData({...fieldsData }); }
                                }}
                            />
                        </Grid>
                        <Grid item className={"single-field-container"} md={4}>
                            <GenericFieldComponent
                                type={'select'}
                                selectId={'countryCode' + phoneItemIndex}
                                selectLabel={getLanguageValue('register-individual.stage3-phone-country-code') + ' *'}
                                selectOptions={[
                                { value: 'select', isDisabled: true, displayText: getLanguageValue('register-individual.stage2-select') },
                                ...(getPhoneExtensionsList().map((item) => ({ value: item.key, displayText: item.value, isDisabled: false })))
                                ]}
                                selectFormClassName={'field-component two-fields-component bottom-margin'}
                                fieldProps={{
                                    native: true,
                                    error: lodashGet(phoneItem, 'errors.countryCode'),
                                    value: phoneItem.countryCode,
                                    inputProps: {
                                        name: 'countryCode' + phoneItemIndex,
                                        id: 'countryCode' + phoneItemIndex
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.countryCode') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], countryCode: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, countryCode: false } }; setFieldsData({...fieldsData }); },
                                }}
                            />
                            <GenericFieldComponent
                                type={"textfield"}
                                fieldProps={{
                                    className: 'field-component two-fields-component',
                                    error: lodashGet(phoneItem, 'errors.phoneNumber'),
                                    value: phoneItem.phoneNumber,
                                    inputProps: {
                                        name: 'phoneNumber'
                                    },
                                    helperText: lodashGet(phoneItem, 'errors.phoneNumber') || '',
                                    onChange: (e) => { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], phoneNumber: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, phoneNumber: false } }; setFieldsData({...fieldsData }); },
                                    onBlur: (e) => { const PLAIN_NUMBER_REGEX = /^[0-9]+$/g; if (!PLAIN_NUMBER_REGEX.test(phoneItem.phoneNumber)) { fieldsData.phones[phoneItemIndex] = { ...fieldsData.phones[phoneItemIndex], phoneNumber: e.target.value, errors: { ...fieldsData.phones[phoneItemIndex].errors, phoneNumber: (!phoneItem.phoneNumber.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid')) } }; setFieldsData({...fieldsData }); } },
                                    label: getLanguageValue('register-individual.stage3-phone-number') + ' *'
                                }}
                            />
                        </Grid>
                        <Grid item className={"single-field-container"} md={4}>
                            {getDisplayedActionForPhoneNumber(phoneItem, phoneItemIndex)}
                        </Grid>
                    </Grid>;
                })}
                {fieldsData.phones.length > 1 && <div>{addPhoneButton}</div>}
            </div>
            <RegisterBottomButtons
                actions={{
                    loaders,
                    isNextDisabled,
                    next,
                    back
                }}
            />
            {fieldErrors.submit ? <div className={"submit-error"}>{fieldErrors.submit}</div> : null}
        </div>
    </RegisterStage3Style>;

    function getDisplayedActionForPhoneNumber(item, itemIndex) {
        if (itemIndex === 0) {
            if (fieldsData.phones.length === 1) {
                // 1 item - use add button
                return addPhoneButton;
            }
            else {
                // more than 1 item - use delete button
                return deletePhoneButton({ itemIndex });
            }
        }
        else {
            // use delete button
            return deletePhoneButton({ itemIndex });
        }
        return null;
    }

    function areAllPhoneValuesOk() {
        for (let loop = 0 ; loop < fieldsData.phones.length ; ++loop) {
            const item = fieldsData.phones[loop];
            if (item.telephoneType == 'select' || !item.countryCode || !item.phoneNumber) {
                return false;
            }
        }
        return true;
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (loaders.next) {
            return;
        }
        if (actions && actions.nextStage) {
            try {
                setLoaders({ ...loaders, next: true });
                let duplicateData = JSON.parse(JSON.stringify(fieldsData));
                // remove leading zeroes of phones
                for (let phoneItem of duplicateData.phones) {
                    delete phoneItem.errors;
                    phoneItem.phoneNumber = String(parseInt(phoneItem.phoneNumber));
                }
                let url = getApiRoot() + 'update-process/?type=individual&languageKey=' + props.languageKey;
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage3'),
                        stage: "3",
                        key: props.individualKey
                    })
                };
                let result = await (await fetch(url, options)).json();
                if (!lodashGet(result, 'data.success')) {
                    setLoaders({ ...loaders, next: false });
                    setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
                    return;
                }
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerIndividualStagesData.stage3Data',
                        value: duplicateData
                    }]
                });
                actions.nextStage();
                return;
            }
            catch (err) {
                // nothing to do
                setFieldErrors({ ...fieldErrors, submit: getLanguageValue('register-network-error') });
            }
            setLoaders({ ...loaders, next: false });
        }
    }

    function back() {
        setTimeout(() => {
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2');
        }, 10);
    }

    function validate(config) {
        let errors = [];

        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of FIELDS) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        let changedFieldsData = false;
        if (Array.isArray(fieldsData.phones)) {
            let index = 0;
            for (let phoneItem of fieldsData.phones) {
                let PLAIN_STRING_REGEX = /^[\u0590-\u05FF]+$/g;
                let PLAIN_NUMBER_REGEX = /^[0-9]+$/g;
                let COUNTRY_CODE_REGEX = /^[\+]{0,1}[0-9]+$/g;
                if (lodashGet(config, 'setErrors')) {
                    changedFieldsData = true;
                    phoneItem.errors = {};
                }
                if (phoneItem.telephoneType == 'select') {
                    errors.push('phones_telephoneType' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['telephoneType'] = getLanguageValue('register-field-errors-must-select');
                    }
                }
                if (!COUNTRY_CODE_REGEX.test(phoneItem.countryCode)) {
                    errors.push('phones_countryCode' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['countryCode'] = !phoneItem.countryCode.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
                    }
                }
                if (!PLAIN_NUMBER_REGEX.test(phoneItem.phoneNumber)) {
                    errors.push('phones_phoneNumber' + index);
                    if (lodashGet(config, 'setErrors')) {
                        changedFieldsData = true;
                        phoneItem.errors['phoneNumber'] = !phoneItem.phoneNumber.length ? getLanguageValue('register-field-errors-empty') : getLanguageValue('register-field-errors-invalid');
                    }
                }
                ++index;
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
            if (changedFieldsData) {
                setFieldsData(fieldsData);
            }
        }
        return errors;
    }
}

const RegisterStage3Style = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  .form-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
    &.addressLine2 label {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
        font-size: 13px;
      }
    }
  }
  .phones-list-item-row {
    border: 1px solid black;
    padding: 10px;
    &.even {
      background-color: #eee;
    }
  }
  .two-fields-component {
    width: calc(50% - 40px);
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE-1}px) {
      width: calc(100% - 20px);
      &.bottom-margin {
        margin-bottom: 20px;
      }
    }
  }
  .add-button-container, .delete-button-container {
    margin-top: 15px;
    button:disabled {
      svg {
        opacity: 0.5;
      }
    }
  }
  .delete-button-container {
    svg {
      color: #A00000;
    }
  }
  .submit-error {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
`;

const RegisterStage3 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        stage3Data: lodashGet(state, 'registerIndividualStagesData.stage3Data')
    }),
    {})(RegisterStage3Component);

export default RegisterStage3;