import React, {useEffect, useState} from 'react';
import { useRoutes, navigate } from 'hookrouter';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import lodashGet from 'lodash/get';
import GlobalStyle from '../style/globalStyle';
import Header from './Header';
import TopMenu from './TopMenu';
import MainPageAlerts from './MainPageAlerts';
import {getContextRoot, getLanguageValue, navigateToPath, scrollToElement} from "../common/functions";
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import StepsBar from './StepsBar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CONSTANTS from '../common/constants';
import RegisterGroupStage1Component from './registerGroupStages/RegisterGroupStage1';
import RegisterGroupStage2Component from './registerGroupStages/RegisterGroupStage2';
import RegisterGroupStagePayComponent from './registerGroupStages/RegisterGroupStagePay';
import RegisterGroupSuccessComponent from './registerGroupStages/RegisterGroupSuccess';
import {ACTIONS} from '../redux/actions';
import {
    Switch,
    Route,
    useLocation,
    Prompt
} from "react-router-dom";
import FloatingWhatsApp from "react-floating-whatsapp";


function RegisterGroupPageBodyComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const [ activeStage, setActiveStage ] = useState(1);
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => { scrollToElement(window.screen.width > 960 ? '.scroll-top-positioning-desktop' : '.scroll-top-positioning-mobile'); }, 10);
    }, [ props.routerRedirect ]);

    useEffect(() => {
        let foundMatch = false;
        // make sure route have expected data
        const allowedPathsWithState = [ '/register-visa-for-group/stage2', '/register-visa-for-group/stagePay', '/register-visa-for-group/stageSuccess' ];
        const allowedPathsWithoutState = [ '/register-visa-for-group', '/register-visa-for-group/status' ];
        for (let path of allowedPathsWithoutState) {
            if (location.pathname == path) {
                // just allow those paths
                foundMatch = true;
            }
        }
        let isStateError = false;
        for (let path of allowedPathsWithState) {
            if (location.pathname == path) {
                foundMatch = true;
                // validate previous state
                if (!props.groupKey) {
                    isStateError = true;
                }
            }
        }
        if (!foundMatch || isStateError) {
            navigateToPath(dispatch, getContextRoot());
        }
        // activate navigation warning
        window.NAVIGATION_WARNING_ENABLED = true;
        return () => {
            window.NAVIGATION_WARNING_ENABLED = false;
        };
    }, []);

    let progressBarItems = [];
    for (let loop = 1 ; loop <= 3 ; ++loop) {
        progressBarItems.push({ active: (loop < activeStage), text: getLanguageValue('register-group.stages-' + loop), icon: <FiberManualRecordIcon className={"progress-icon" + (loop <= activeStage ? ' active' : '')} /> });
    }

    return <>
    <GlobalStyle/>
    <RegisterGroupPageBodyStyle>
        {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
            className={'floating-whatsapp'}
            phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
            accountName={getLanguageValue('whatsapp.header-name')}
            statusMessage={getLanguageValue('whatsapp.status-message')}
            chatMessage={getLanguageValue('whatsapp.initial-message')}
        />}
        <Header className="header-component"/>
        <HeaderWithLogoBackground className="header-background-component" />
        <MainPageAlerts />
        <Prompt when={true} message={getLanguageValue('register-common.warning-in-progress')} />
        <TopMenu className="top-menu-component" activeMenu={isStatusPath() ? 'check-visa-status' : 'register-visa'} disableAction={isStatusPath() ? 'retrieve-group-application' : 'register-visa-for-group'}/>
        <div className="content-body-container">
            <div className={"scroll-top-positioning-desktop"} />
            <div className={"steps-container"}>
                <StepsBar items={progressBarItems} activeStage={activeStage} className={"steps-component"} itemPercentage={parseInt(100 / progressBarItems.length)} />
            </div>
            <div className={"scroll-top-positioning-mobile"} />
            <div className="form-container">
                <Switch>

                    <Route path="/register-visa-for-group/stage2">
                        <RegisterGroupStage2Component actions={{ setActiveStage, nextStage: () => { setActiveStage(3); setTimeout(() => { navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/stagePay'); },10);  } } } />
                    </Route>

                    <Route path="/register-visa-for-group/status">
                        <RegisterGroupStage2Component actions={{ setActiveStage } } />
                    </Route>

                    <Route path="/register-visa-for-group/stagePay">
                        <RegisterGroupStagePayComponent actions={{ setActiveStage } } />
                    </Route>

                    <Route path="/register-visa-for-group/stageSuccess">
                        <RegisterGroupSuccessComponent actions={{ setActiveStage } } />
                    </Route>

                    <Route path="/register-visa-for-group/">
                        <RegisterGroupStage1Component actions={{ setActiveStage, nextStage: () => { setActiveStage(2); setTimeout(() => { navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/stage2'); }, 10); } } } />
                    </Route>
                </Switch>
            </div>
        </div>
        <MainFooter className="footer-component" />
    </RegisterGroupPageBodyStyle>
    </>;

    function isValidForStage() {
        // check if all data is ok. if data not ok - redirect to first stage
    }

    function isStatusPath() {
        if (location.pathname == '/register-visa-for-group/status') {
            return true;
        }
        return false;
    }
}

const RegisterGroupPageBodyStyle = styled.div`
 .steps-container {
    padding: 25px;
    background-color: #eee;
 }
 .steps-component {
    .progress-icon {
      color: white;
      &.active {
        color: #1460aa;
      }
    }
    .progress-icon > path {
      fill: #aaa;
    }
    .progress-bar-text {
      color: black;
    }
 }
`;

const RegisterGroupPageBody = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        groupKey: lodashGet(state, 'registerGroupStagesData.key'),
        routerRedirect: state.routerRedirect
    }),
    {})(RegisterGroupPageBodyComponent);

export default RegisterGroupPageBody;