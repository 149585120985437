import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {changeLanguage, getLanguageValue, updateLanguageData} from "../common/functions";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Flag from "./Flag";
import { ACTIONS } from "../redux/actions";
import CONSTANTS from '../common/constants';
import Loader from "./Loader";

function Header(props) {

    const dispatch = useDispatch();
    const buttonRef = useRef();
    const [ isMenuVisible, setIsMenuVisible ] = useState(false);
    const [ isEnabled, setIsEnabled ] = useState(CONSTANTS.LANGUAGE_SELECT_ENABLED);
    const [ isSystemReady, setIsSystemReady ] = useState(false);
    const languageOptions = getLanguageValue('header.language-options');

    useEffect(() => {
        if (CONSTANTS.STRIPE_KEY) {
            // system ready
            setIsSystemReady(true);
        }
        if (!CONSTANTS.LANGUAGE_SELECT_ENABLED && isEnabled) {
            setIsEnabled(false);
        }
    });

    return <HeaderStyle className={props.className}>
        <div className="left-message">{getLanguageValue('header.top-message')}</div>
        {!isSystemReady && <Loader className={'loader-component'} />}
        {isSystemReady && isEnabled && <div className="language-select-container">
            <div className="language-select-text">{getLanguageValue('header.change-language')}</div>
            <Button disabled={false} className="language-select-button" aria-controls="simple-menu" ref={buttonRef} aria-haspopup="true" onClick={(e) => { setIsMenuVisible(true); } }><Flag flagKey={getLanguageValue('key')} /> {getLanguageValue('header.current-language')}</Button>
            <Menu id="language-menu-component" className="menu-container" anchorEl={buttonRef.current} keepMounted open={isMenuVisible} onClose={() => { setIsMenuVisible(false); }} >
                {
                    languageOptions.map(function(item, itemIndex) {
                        return <MenuItem className={"language-option" + (getLanguageValue('header.current-language') == item.display ? ' selected' : '') + ' ' + item.key} key={itemIndex} onClick={() => selectLanguage(item)}><Flag flagKey={item.key} />{item.display}</MenuItem>
                    })
                }
            </Menu>
        </div>}
    </HeaderStyle>;

    function selectLanguage(languageObject) {
        setIsMenuVisible(false);
        changeLanguage(languageObject.key, function() {
            updateLanguageData();
            dispatch({
                type: ACTIONS.SET_LANGUAGE,
                payload: languageObject.key
            });
        });
    }
}

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: lightgray;
  .loader-component {
    margin-right: 15px;
    margin-top: 8px;
  }
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    display: block;
    text-align: center;
  }
  .left-message {
    line-height: 42px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .language-select-container {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      text-align: center;
    }
  }
  .language-select-text, .language-select-button {
    display: inline-block;
  }
  .language-select-text {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
  }
`;


export default Header;
