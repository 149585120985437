import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import GlobalStyle from '../style/globalStyle';
import { ACTIONS } from "../redux/actions";
import lodashGet from 'lodash/get';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, getHelpTopics} from "../common/functions";
import CONSTANTS from '../common/constants';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { createGlobalStyle } from 'styled-components';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import FloatingWhatsApp from "react-floating-whatsapp";

function RegulationsPageBodyComponent(props) {

    useEffect(() => {
        let metaElement = document.createElement("meta");
        metaElement.id = 'regulations-meta';
        metaElement.name = 'googlebot';
        metaElement.content = 'noindex';
        window.document.head.appendChild(metaElement);
        return () => {
            try {
                let metaElement = document.querySelector('#regulations-meta');
                metaElement.parentNode.removeChild(metaElement);
            }
            catch (err) {
                // nothing to do
            }
        };
    }, []);

    return <>
        <GlobalStyle/>
        <MainPageBodyStyle>
            {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
                className={'floating-whatsapp'}
                phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
                accountName={getLanguageValue('whatsapp.header-name')}
                statusMessage={getLanguageValue('whatsapp.status-message')}
                chatMessage={getLanguageValue('whatsapp.initial-message')}
            />}
            <Header className="header-component"/>
            <HeaderWithLogoBackground className="header-background-component" />
            <TopMenu className="top-menu-component" />
            <div className="content-body-container" dangerouslySetInnerHTML={{ __html: getLanguageValue('regulations-content-html')}} />
            <MainFooter className="footer-component" />
        </MainPageBodyStyle>
    </>;
}

const MainPageBodyStyle = styled.div`
  .content-body-container {
    padding: 25px;
    li {
      font-size: 22px;
      padding-bottom: 5px;
    }
  }
`;


const RegulationsPageBody = connect(
    (state) => ({
        languageKey: state.languageKey // make everything re-render
    }),
    {})(RegulationsPageBodyComponent);

export default RegulationsPageBody;