import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {getContextRoot, getApiRoot, getLanguageValue, getQueryParam, navigateToPath, googleRecaptcha, changeLanguage, getFileType} from "../../common/functions";
import { useParams } from 'react-router-dom';
import lodashGet from 'lodash/get';
import CONSTANTS from '../../common/constants';
import Loader from "../Loader";
import Button from '@material-ui/core/Button';
import { CSS_COLORS } from '../../common/cssColors';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CropHandler from '../CropHandler';
import lodashSet from "lodash/set";


function AgentManagerPageBodyComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    // modal vars
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ apiResult, setApiResult ] = useState(null);
    const [ selectedPersonToView, setSelectedPersonToView ] = useState(null);
    const [ loaders, setLoaders ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    let [ modifiedUserFileObjects, setModifiedUserFileObjects ] = useState({});
    let [ fileObjects, setFileObjects ] = useState([]);
    let [ cropHandlerControls , setCropHandlerControls ] = useState({
        passport: {},
        passportFace: {},
        birthCertificate: {},
        parentPassport: {},
        passportBackside: {}
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });
    cropHandlerControls.passportFace.callback = cropHandlerCallback.bind({ fileType: 'passportFace' });
    cropHandlerControls.birthCertificate.callback = cropHandlerCallback.bind({ fileType: 'birthCertificate' });
    cropHandlerControls.parentPassport.callback = cropHandlerCallback.bind({ fileType: 'parentPassport' });
    cropHandlerControls.passportBackside.callback = cropHandlerCallback.bind({ fileType: 'passportBackside' });

    const [ fileObjectsToDelete, setFileObjectsToDelete ] = useState([]);
    const [ errors, setErrors ] = useState({ });
    const flightCompanies = getLanguageValue('register-individual.stage4-flight-companies-array').split(',').map((value) => ({ value, displayText: getLanguageValue('register-individual.stage4-flight-company-' + value) }));
    const selectedFlightCompanyDisplayText = lodashGet(flightCompanies.find((item) => item.value == lodashGet(selectedPersonToView, 'stage4Data.flightCompany')), 'displayText');
    const [ rejectReasons, setRejectReasons ] = useState({ });
    const [ showRejectWarning, setShowRejectWarning ] = useState(false);

    const isDebugging = getQueryParam('debug');

    const routerProps = useParams();

    useEffect(() => {
        document.title = 'Agent Manager';
        changeLanguage('en', async function() {
            try {

                let url = getApiRoot() + 'agent-api/agent-retrieve-information/';
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        key: routerProps.id
                    })
                };
                let result = await (await fetch(url, options)).json();
                setApiResult(result);
                if (lodashGet(result, 'data.success')) {
                    if (lodashGet(result, 'data.type') == 'individual') {
                        selectPersonFromGroup(lodashGet(result, 'data.individualApplication'));
                        if (lodashGet(result, 'data.individualApplication.status') == 'reject' && lodashGet(result, 'data.individualApplication.rejectReasons')) {
                            setRejectReasons(lodashGet(result, 'data.individualApplication.rejectReasons'));
                        }
                    }
                }
                else {
                    setApiResult({ err: 'general error' });
                }
            }
            catch (err) {
                // nothing to do
                setApiResult({ err: 'general error' });
            }
        });
    }, []);

    if (!routerProps.id) {
        navigateToPath(dispatch, getContextRoot());
        return;
    }

    return <>
        <AgentManagerStyle>
            <img className={"logo-image"} src={getContextRoot() + 'images/logo.png'} />
            <br/>
            <h1>VisaLikum Agent Manager</h1>
            {!apiResult ? <>Loading...<Loader /></> : null }
            {apiResult && apiResult.err ? <><div className="general-error-message">Failed to load data. Please cotact support with (1) your link, (2) current time.</div></> : null }
            { apiResult && apiResult.data ? <>
            <h2>{lodashGet(apiResult, 'data.type') == 'group' ? 'Group Application Information (' +  lodashGet(apiResult, 'data.groupApplication.key') + ')' : 'Individual Application Information (' + lodashGet(apiResult, 'data.individualApplication.key') + ')'}</h2>

            <div className={"group-view-container"}>
                {lodashGet(apiResult, 'data.type') == 'group' && <div className={"group-short-instructions-container"}>
                    <div className={"group-short-instructions-inner-container"}>
                        1){ selectedPersonToView ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Select A Person
                        <br/>
                        2){isGroupReady() || isIndividualPassedStage(2) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click "start procedure"
                        <br/>
                        3) {isGroupReady() || isUploadedVisa() ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Upload visa files in the end
                        <br/>
                        4) {isGroupReady() || isIndividualPassedStage(4) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click finish or reject (once you'll finish all persons, the visas / rejections will be sent automatically to group owner email)
                        <br/>
                        <br/>
                    </div>
                </div>}
                {lodashGet(apiResult, 'data.type') == 'individual' && <div className={"individual-short-instructions-container"}>
                    1){isIndividualPassedStage(1) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click "start procedure"
                    <br/>
                    2) {isUploadedVisa() ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Upload visa files in the end
                    <br/>
                    3) {isIndividualPassedStage(3) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click finish or reject (once you'll finish, the visa or the rejection will be sent automatically to the person email)
                    <br/>
                    <br/>
                </div>}
                <table className={"main-table-component"} border="1">
                    {lodashGet(apiResult, 'data.type') == 'group' && <thead>
                        <tr>
                            <th className={"sticky-header"}>
                                Person select <br/><br/>{getGroupPersons()}
                            </th>
                        </tr>
                    </thead>}
                    <tbody>
                        <tr>
                            <td>
                                {selectedPersonToView != null && <div>
                                    <div className="start-procedure-button-container">
                                        <Button disabled={isIndividualPassedStage(2)} className={"agent-action-button"} onClick={startProcedure}>Start Procedure {loaders.startProcedure && <>&nbsp;<Loader /></>}</Button>
                                        {errors.startProcedure && <div className={"error-message-container"}>Failed to start procedure</div>}
                                    </div>
                                    <div className={"files-information-container"}>
                                        <hr/>
                                        <h2 className={"table-main-h2"}>User Files:</h2>
                                        <hr/>
                                        <br/><br/>

                                        <div className="divglen">
                                        <h1>FOR GLENN</h1>
                                        <Button disabled={0} className={"agent-accept-action-button"} onClick={glenApproveOrder}>APPROVE {loaders.startApprove && <>&nbsp;<Loader /></>}</Button>
                                        {errors.startApprove && <div className={"error-message-container"}>Failed to approve</div>}
                                        <br/>
                                        <br/>
                                        <Button disabled={0} className={"agent-ask-action-button"} onClick={glenAskOrder}>ASK FOR MORE DOCUMENTS {loaders.startAsk && <>&nbsp;<Loader /></>}</Button>
                                        {errors.startAsk && <div className={"error-message-container"}>Failed to ask</div>}
                                        <br/>

                                        </div>



                                        <div className={"file-item-container"}>
                                            <b>1) Passport</b>
                                            <br/>
                                            <input type="file" id="passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passport', '.client-pictures-item-container.passport'); }} />
                                            {getPersonFileType('passport') && <React.Fragment>
                                                <div className="client-pictures-item-container passport">
                                                    <img className={"client-pictures-component passport"} src={getPersonFileType('passport').fileContent} alt={""} />
                                                    <CropHandler controls={cropHandlerControls.passport} dimensions={{ width: 450, height: 350 }} imageCssSelector={'.client-pictures-component.passport'} imageSrc={lodashGet(modifiedUserFileObjects, 'passport.fileContent')} />
                                                </div>

                                                <br/>
                                                {lodashGet(modifiedUserFileObjects, 'passport.finished') && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passport': null }); }}>Undo changes</Button></div></>}
                                                <br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passport.fileContent') || lodashGet(modifiedUserFileObjects, 'passport.finished')) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passport': { fileContent: getPersonFileType('passport').fileContent } }); cropHandlerControls.passport.restart(); }}>Edit</Button>}
                                                <br/><br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passport.fileContent') || lodashGet(modifiedUserFileObjects, 'passport.finished')) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-file-upload-field').click(); }}>Upload new image</Button>}
                                                {lodashGet(modifiedUserFileObjects, 'passport.finished') && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, 'passport', 'saveImagePassport'); }}>Upload image to server {loaders.saveImagePassport && <Loader />}</Button></div></>}
                                                {lodashGet(modifiedUserFileObjects, 'passport.error') && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, 'passport.error')}</div></>}
                                            </React.Fragment>}
                                            {!getPersonFileType('passport') && <React.Fragment>
                                                <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-file-upload-field').click(); }}>Upload new image</Button></div>
                                                <span className={"warning-color"}>None. Please contact support if required (at the bottom)</span>
                                            </React.Fragment>}
                                        </div>
                                        <br/><br/>
                                        <div className={"file-item-container"}>
                                            <b>2) Passport Face</b>
                                            <br/>
                                            <input type="file" id="passport-face-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passportFace', '.client-pictures-item-container.passport-face'); }} />
                                            {getPersonFileType('passportFace') && <React.Fragment>
                                                <div className="client-pictures-item-container passport-face">
                                                    <img className={"client-pictures-component passport-face"} src={getPersonFileType('passportFace').fileContent} alt={""} />
                                                    <div><CropHandler controls={cropHandlerControls.passportFace} dimensions={{ width: 350, height: 450 }} imageCssSelector={'.client-pictures-component.passport-face'} imageSrc={lodashGet(modifiedUserFileObjects, 'passportFace.fileContent')} /></div>
                                                </div>
                                                <br/>
                                                {lodashGet(modifiedUserFileObjects, 'passportFace.finished') && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passportFace': null }); }}>Undo changes</Button></div></>}
                                                <br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passportFace.fileContent') || lodashGet(modifiedUserFileObjects, 'passportFace.finished')) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passportFace': { fileContent: getPersonFileType('passportFace').fileContent } }); cropHandlerControls.passportFace.restart(); }}>Edit</Button>}
                                                <br/><br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passportFace.fileContent') || lodashGet(modifiedUserFileObjects, 'passportFace.finished')) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-face-file-upload-field').click(); }}>Upload new image</Button>}
                                                {lodashGet(modifiedUserFileObjects, 'passportFace.finished') && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, 'passportFace', 'saveImagePassportFace'); }}>Upload image to server {loaders.saveImagePassportFace && <Loader />}</Button></div></>}
                                                {lodashGet(modifiedUserFileObjects, 'passportFace.error') && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, 'passportFace.error')}</div></>}
                                            </React.Fragment>}
                                            {!getPersonFileType('passportFace') && <React.Fragment>
                                                <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-face-file-upload-field').click(); }}>Upload new image</Button></div>
                                                <span className={"warning-color"}>None. Please contact support if required (at the bottom)</span>
                                            </React.Fragment>}
                                        </div>
                                        <br/><br/>
                                        <div className={"file-item-container"}>
                                            <b>3) Birth certificate (under 18)</b>
                                            <br/>
                                            <input type="file" id="birth-certificate-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'birthCertificate', '.client-pictures-item-container.birth-certificate'); }} />
                                            {getPersonFileType('birthCertificate') && <React.Fragment>
                                                <div className="client-pictures-item-container birth-certificate">
                                                    <img className={"client-pictures-component birth-certificate"} src={getPersonFileType('birthCertificate').fileContent} alt={""} />
                                                    <div><CropHandler controls={cropHandlerControls.birthCertificate} dimensions={{ width: 600, height: 800 }} imageCssSelector={'.client-pictures-component.birth-certificate'} imageSrc={lodashGet(modifiedUserFileObjects, 'birthCertificate.fileContent')} /></div>
                                                </div>
                                                <br/>
                                                {lodashGet(modifiedUserFileObjects, 'birthCertificate.finished') && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'birthCertificate': null }); }}>Undo changes</Button></div></>}
                                                <br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'birthCertificate.fileContent') || lodashGet(modifiedUserFileObjects, 'birthCertificate.finished')) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'birthCertificate': { fileContent: getPersonFileType('birthCertificate').fileContent } }); cropHandlerControls.birthCertificate.restart(); }}>Edit</Button>}
                                                <br/><br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'birthCertificate.fileContent') || lodashGet(modifiedUserFileObjects, 'birthCertificate.finished')) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#birth-certificate-file-upload-field').click(); }}>Upload new image</Button>}
                                                {lodashGet(modifiedUserFileObjects, 'birthCertificate.finished') && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, 'birthCertificate', 'saveImageBirthCertificate'); }}>Upload image to server {loaders.saveImageBirthCertificate && <Loader />}</Button></div></>}
                                                {lodashGet(modifiedUserFileObjects, 'birthCertificate.error') && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, 'birthCertificate.error')}</div></>}
                                            </React.Fragment>}
                                            {!getPersonFileType('birthCertificate') && <React.Fragment>
                                                <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#birth-certificate-file-upload-field').click(); }}>Upload new image</Button></div>
                                                <span className={"warning-color"}>None.</span>
                                            </React.Fragment>}
                                        </div>
                                        <br/><br/>
                                        <div className={"file-item-container"}>
                                            <b>4) Parent Passport (under 18)</b>
                                            <br/>
                                            <input type="file" id="parent-passport-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'parentPassport', '.client-pictures-item-container.parent-passport'); }} />
                                            {getPersonFileType('parentPassport') && <React.Fragment>
                                                <div className="client-pictures-item-container parent-passport">
                                                    <img className={"client-pictures-component parent-passport"} src={getPersonFileType('parentPassport').fileContent} alt={""} />
                                                    <div><CropHandler controls={cropHandlerControls.parentPassport} dimensions={{ width: 450, height: 350 }} imageCssSelector={'.client-pictures-component.parent-passport'} imageSrc={lodashGet(modifiedUserFileObjects, 'parentPassport.fileContent')} /></div>
                                                </div>
                                                <br/>
                                                {lodashGet(modifiedUserFileObjects, 'parentPassport.finished') && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'parentPassport': null }); }}>Undo changes</Button></div></>}
                                                <br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'parentPassport.fileContent') || lodashGet(modifiedUserFileObjects, 'parentPassport.finished')) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'parentPassport': { fileContent: getPersonFileType('parentPassport').fileContent } }); cropHandlerControls.parentPassport.restart(); }}>Edit</Button>}
                                                <br/><br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'parentPassport.fileContent') || lodashGet(modifiedUserFileObjects, 'parentPassport.finished')) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#parent-passport-file-upload-field').click(); }}>Upload new image</Button>}
                                                {lodashGet(modifiedUserFileObjects, 'parentPassport.finished') && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, 'parentPassport', 'saveImageParentPassport'); }}>Upload image to server {loaders.saveImageParentPassport && <Loader />}</Button></div></>}
                                                {lodashGet(modifiedUserFileObjects, 'parentPassport.error') && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, 'parentPassport.error')}</div></>}
                                            </React.Fragment>}
                                            {!getPersonFileType('parentPassport') && <React.Fragment>
                                                <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#parent-passport-file-upload-field').click(); }}>Upload new image</Button></div>
                                                <span className={"warning-color"}>None.</span>
                                            </React.Fragment>}
                                        </div>
                                        <br/><br/>
                                        <div className={"file-item-container"}>
                                            <b>5) Passport backside (country of citizenship is India)</b>
                                            <br/>
                                            <input type="file" id="passport-backside-file-upload-field" onChange={(e) => { onFileUploadChange(e, 'passportBackside', '.client-pictures-item-container.passport-backside'); }} />
                                            {getPersonFileType('passportBackside') && <React.Fragment>
                                                <div className="client-pictures-item-container passport-backside">
                                                    <img className={"client-pictures-component passport-backside"} src={getPersonFileType('passportBackside').fileContent} alt={""} />
                                                    <div><CropHandler controls={cropHandlerControls.passportBackside} dimensions={{ width: 450, height: 350 }} imageCssSelector={'.client-pictures-component.passport-backside'} imageSrc={lodashGet(modifiedUserFileObjects, 'passportBackside.fileContent')} /></div>
                                                </div>
                                                <br/>
                                                {lodashGet(modifiedUserFileObjects, 'passportBackside.finished') && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passportBackside': null }); }}>Undo changes</Button></div></>}
                                                <br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passportBackside.fileContent') || lodashGet(modifiedUserFileObjects, 'passportBackside.finished')) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, 'passportBackside': { fileContent: getPersonFileType('passportBackside').fileContent } }); cropHandlerControls.passportBackside.restart(); }}>Edit</Button>}
                                                <br/><br/>
                                                {(!lodashGet(modifiedUserFileObjects, 'passportBackside.fileContent') || lodashGet(modifiedUserFileObjects, 'passportBackside.finished')) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-backside-file-upload-field').click(); }}>Upload new image</Button>}
                                                {lodashGet(modifiedUserFileObjects, 'passportBackside.finished') && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, 'passportBackside', 'saveImagePassportBackside'); }}>Upload image to server {loaders.saveImagePassportBackside && <Loader />}</Button></div></>}
                                                {lodashGet(modifiedUserFileObjects, 'passportBackside.error') && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, 'passportBackside.error')}</div></>}
                                            </React.Fragment>}
                                            {!getPersonFileType('passportBackside') && <React.Fragment>
                                                <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#passport-backside-file-upload-field').click(); }}>Upload new image</Button></div>
                                                <span className={"warning-color"}>None.</span>
                                            </React.Fragment>}
                                        </div>
                                    </div>
                                    <hr/>
                                    <div>
                                        <h2 className={"table-main-h2"}>User Form ({selectedPersonToView.key}) details:</h2>
                                    </div>
                                    <hr/>
                                    <h3><u>Applicant Information:</u></h3>
                                    <div className="stage-content-container">
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Last Name</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.lastNameEnglish')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>First Name</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.firstNameEnglish')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Gender</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.gender')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Birth Date (dd/mm/yyyy)</b>
                                                <br/>
                                                {moment(lodashGet(selectedPersonToView, 'stage2Data.birthDate')).format('DD/MM/YYYY')}
                                            </Grid>
                                        </Grid>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>City Of Birth</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.cityOfBirth')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Country of Birth</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.countryOfBirth')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Passport Number</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.passportNumber')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Country Of Citizenship</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.countryOfCitizenship')}
                                            </Grid>
                                        </Grid>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Issuance Date (dd/mm/yyyy)</b>
                                                <br/>
                                                {moment(lodashGet(selectedPersonToView, 'stage2Data.issuanceDate')).format('DD/MM/YYYY')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Expiration Date (dd/mm/yyyy)</b>
                                                <br/>
                                                {moment(lodashGet(selectedPersonToView, 'stage2Data.expirationDate')).format('DD/MM/YYYY')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Issuing Country</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.issuingCountry')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Visa duration</b>
                                                <br/>
                                                <span className={"special-text-display"}>{translateVisaType(lodashGet(selectedPersonToView, 'stage2Data.visaType'))}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid className={"fields-row-container last"} container>
                                            {false && <Grid item className={"field-container"} md={3}>
                                                <b>Is Expedited</b>
                                                <br/>
                                                <span className={"special-text-display"}>{lodashGet(selectedPersonToView, 'stage2Data.visaExpeditedFlag') ? 'Yes' : 'No'}</span>
                                            </Grid>}
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Arrival date (dd/mm/yyyy)</b>
                                                <br/>
                                                {moment(lodashGet(selectedPersonToView, 'stage2Data.arrivalDate')).format('DD/MM/YYYY') }
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Flight country origin</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage2Data.flightCountryOrigin')}
                                            </Grid>
                                        </Grid>

                                        <div className={"citizen-of-any-other-country-question"}>Citizen of other countries - <b>{lodashGet(selectedPersonToView, 'stage2Data.questionAreYouACitizenOfOtherCountry')}</b></div>
                                        {lodashGet(selectedPersonToView, 'stage2Data.questionAreYouACitizenOfOtherCountry') == 'yes' && <><table className={"citizen-of-other-country-table table-component"}>
                                            <thead>
                                            <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                                <td>Country of citizenship or nationality</td>
                                                <td>How citizenship received</td>
                                                <td>Other comment</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedPersonToView.stage2Data.currentlyCitizenOfCountries && selectedPersonToView.stage2Data.currentlyCitizenOfCountries.length > 0 && selectedPersonToView.stage2Data.currentlyCitizenOfCountries.map(function(item, itemIndex) {
                                                    return <tr key={itemIndex}>
                                                        <td>
                                                            {item.countryOfCitizenship}
                                                        </td>
                                                        <td>
                                                            {item.howCitizenshipReceived}
                                                        </td>
                                                        <td>
                                                            {item.other}
                                                        </td>
                                                    </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table></>}
                                        <div className="citizen-of-any-other-country-question">Was a citizen of other countries - <b>{lodashGet(selectedPersonToView, 'stage2Data.questionWereYouACitizenOfOtherCountry')}</b></div>
                                        {lodashGet(selectedPersonToView, 'stage2Data.questionWereYouACitizenOfOtherCountry') == 'yes' && <><table className={"citizen-of-other-country-table table-component"}>
                                            <thead>
                                            <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                                <td>Country of citizenship or nationality</td>
                                                <td>Citizenship start date (dd/mm/yyyy)</td>
                                                <td>Citizenship end date (dd/mm/yyyy)</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                lodashGet(selectedPersonToView, 'stage2Data.previousCitizenOfCountries.length') > 0 && selectedPersonToView.stage2Data.previousCitizenOfCountries.map(function(item, itemIndex) {
                                                    return <tr key={itemIndex}>
                                                        <td>
                                                            {item.countryOfCitizenship}
                                                        </td>
                                                        <td>
                                                            {moment(item.startDate).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            {moment(item.endDate).format('DD/MM/YYYY')}
                                                        </td>
                                                    </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table></>}
                                        <hr/>
                                        <h3 style={{ display: 'none' }}><u>Personal Information:</u></h3>
                                        <Grid style={{ display: 'none' }} className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Address Line 1</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.addressLine1')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Address Line 2</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.addressLine2')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Apartment Number</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.apartmentNumber')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>City</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.city')}
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'none' }} className={"fields-row-container last"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>State Or Province</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.stateOrProvince')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Country</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage3Data.country')}
                                            </Grid>
                                        </Grid>
                                        <div style={{ display: 'none' }} className={"stage3-phones-container"}>
                                            <b>Phones:</b>
                                            <table className={"stage3-phones-table table-component"}>
                                                <thead>
                                                <tr className={"stage3-phones-table-header table-header-component"}>
                                                    <td>Type</td>
                                                    <td>Country code</td>
                                                    <td>Phone number</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    lodashGet(selectedPersonToView, 'stage3Data.phones.length') > 0 && selectedPersonToView.stage3Data.phones.map(function(item, itemIndex) {
                                                        return <tr key={itemIndex}>
                                                            <td>
                                                                {item.telephoneType}
                                                            </td>
                                                            <td>
                                                                {item.countryCode}
                                                            </td>
                                                            <td>
                                                                {item.phoneNumber}
                                                            </td>
                                                        </tr>;
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr style={{ display: 'none' }}/>
                                        <h3 style={{ display: 'none' }}><u>Flight Information:</u></h3>
                                        <div  style={{ display: 'none' }} className="stage-content-container">
                                            <Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Flight Date</b>
                                                    <br/>
                                                    {moment(lodashGet(selectedPersonToView, 'stage4Data.flightDate')).format('DD/MM/YYYY')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Flight Number</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.flightNumber')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Flight Company</b>
                                                    <br/>
                                                    {selectedFlightCompanyDisplayText}
                                                </Grid>
                                            </Grid>
                                            <h4>Flight Hotel Destination Information</h4>
                                            <Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Hotel Name</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelName')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Hotel Address</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelAddress')}
                                                </Grid>
                                            </Grid>
                                            <h4>Contact Information</h4>
                                            <Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact Name</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.contactName')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact Address Line 1</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.contactAddressLine1')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact Address Line 2</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.contactAddressLine2')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact Apartment Number</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.contactApartmentNumber')}
                                                </Grid>
                                            </Grid>
                                            <Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact city</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.contactCity')}
                                                </Grid>
                                            </Grid>
                                            <h4>Address while in country</h4>
                                            {!lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeHotelAddressFlag') ? <><Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Addres line 1</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine1' : 'stage4Data.addressLine1')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Address Line 2</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine2' : 'stage4Data.addressLine2')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Apartment Number</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactApartmentNumber' : 'stage4Data.apartmentNumber')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>City</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactCity' : 'stage4Data.city')}
                                                </Grid>
                                            </Grid></> : <Grid item className={"field-container"} md={3}>
                                                {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelAddress')}
                                            </Grid>}
                                            <h4>Contact in case of emergency</h4>
                                            <Grid className={"fields-row-container"} container>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact name</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactFirstName') + ' ' + lodashGet(selectedPersonToView, 'stage4Data.emergencyContactLastName')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact email</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactEmail')}
                                                </Grid>
                                                <Grid item className={"field-container"} md={3}>
                                                    <b>Contact phone</b>
                                                    <br/>
                                                    {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactPhoneCountryCode') + lodashGet(selectedPersonToView, 'stage4Data.emergencyContactPhoneNumber')}
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <hr/>
                                        <h3><u>Personal Questions:</u></h3>
                                        <div className={"stage5-questions-container"}>
                                            {getStage5QuestionsList(lodashGet(selectedPersonToView, 'stage5Data')).map((questionItem, questionItemIndex) => (
                                                <Grid key={questionItemIndex} className={"question-and-input-container question-key-" + questionItem.key}>
                                                    <div dangerouslySetInnerHTML={{ __html: questionItem.value }} />
                                                    <div className={"answer-container"}>
                                                        <b>{questionItem.answer}</b>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className={"table-main-h2"}>Upload Visa files {isUploadDisabled('title') && <span className={"disabled-notification"}>(Disabled until you will click "START PROCEDURE")</span>}</h2>
                                    </div>
                                    <div className={"upload-visa-container"}>
                                        <div className={"upload-visa-dropzone-container" + (isUploadDisabled() ? ' inactive' : '')}>
                                            <DropzoneAreaBase
                                                dropzoneClass={"upload-visa-dropzone"}
                                                previewGridClasses={{
                                                    container: 'upload-visa-dropzone-preview-container',
                                                    item: 'upload-visa-dropzone-preview-item',
                                                    image: 'upload-visa-dropzone-preview-image'
                                                }}
                                                dropzoneParagraphClass={"upload-visa-dropzone-paragraph"}
                                                fileObjects={fileObjects}
                                                showPreviews={true}
                                                showFileNamesInPreview={true}
                                                showPreviewsInDropzone={false}
                                                onAdd={newFileObjs => {
                                                    if (isUploadDisabled()) {
                                                        return false;
                                                    }
                                                    setFileObjects([].concat(fileObjects, newFileObjs));
                                                }}
                                                onDelete={deleteFileObj => {
                                                    if (isUploadDisabled()) {
                                                        return false;
                                                    }
                                                    setFileObjects(fileObjects.filter(item => item !== deleteFileObj));
                                                    setFileObjectsToDelete([ ...fileObjectsToDelete, deleteFileObj ]);
                                                }}
                                            />
                                        </div>
                                        <Button disabled={isUploadDisabled()} className={"upload-all-files-button"} onClick={uploadAllVisaFiles}>Save files changes {loaders.uploadAllFiles ? <>&nbsp;<Loader /></> : null}</Button>
                                        {errors.uploadAllFiles && <div className={"upload-error-container"}>failed to save file changes. Please refresh the page and try again</div>}
                                    </div>
                                    <div>
                                        <h2 className={"table-main-h2"}>Finish</h2>
                                    </div>
                                    <Button disabled={isFinishDisabled()} className={"finish-button"} onClick={() => finish('ready')}>Finish {loaders.ready && <Loader />}</Button>
                                    <br/>
                                    <Button disabled={isFinishDisabled({ ignoreFiles: true })} className={"finish-button"} onClick={() => finish('readyWithoutFiles')}>Finish without files{loaders.readyWithoutFiles && <Loader />}</Button>
                                    <br/>
                                    <Button disabled={isRejectDisabled()} className={"reject-button"} onClick={() => finish('reject')}>Reject With Reason {loaders.reject && <Loader />}</Button>
                                    {showRejectWarning && <div className={"reject-reason-error-text"}>Please select at least 1 reject reason</div>}
                                    <br/>
                                    <div className={"reject-reasons-container" + (showRejectWarning ? ' warning' : '')}>
                                        <div className={""}>REJECT REASONS:</div>
                                        Images: <FormControlLabel
                                        className={"reject-reason-passport"}
                                        control={
                                            <Checkbox
                                                disabled={loaders.reject || isRejectDisabled()}
                                                checked={rejectReasons.passport || false}
                                                onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, passport: e.target.checked}); }}
                                                name="rejectReasonPassport"
                                                color="primary"
                                            />
                                        }
                                        label={'Passport'}
                                    />
                                        <FormControlLabel
                                            className={"reject-reason-passport-face"}
                                            control={
                                                <Checkbox
                                                    disabled={loaders.reject || isRejectDisabled()}
                                                    checked={rejectReasons.passportFace || false}
                                                    onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, passportFace: e.target.checked}); }}
                                                    name="rejectReasonPassportFace"
                                                    color="primary"
                                                />
                                            }
                                            label={'Passport Face'}
                                        />
                                        {selectedPersonToView.files.filter((fileItem, fileItemIndex) => fileItem.fileType == 'birthCertificate').length > 0 && <FormControlLabel
                                            className={"reject-reason-birth-certificate"}
                                            control={
                                                <Checkbox
                                                    disabled={loaders.reject || isRejectDisabled()}
                                                    checked={rejectReasons.birthCertificate || false}
                                                    onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, birthCertificate: e.target.checked}); }}
                                                    name="rejectReasonBirthCertificate"
                                                    color="primary"
                                                />
                                            }
                                            label={'Birth Certificate'}
                                        />}
                                        {selectedPersonToView.files.filter((fileItem, fileItemIndex) => fileItem.fileType == 'parentPassport').length > 0 && <FormControlLabel
                                            className={"reject-reason-parent-passport"}
                                            control={
                                                <Checkbox
                                                    disabled={loaders.reject || isRejectDisabled()}
                                                    checked={rejectReasons.parentPassport || false}
                                                    onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, parentPassport: e.target.checked}); }}
                                                    name="rejectReasonParentPassport"
                                                    color="primary"
                                                />
                                            }
                                            label={'Parent Passport'}
                                        />}
                                    </div>
                                    <br/>
                                    <Button disabled={isRejectDisabled()} className={"reject-button"} onClick={() => finish('rejectPermanently')}>Reject Permanently {loaders.rejectPermanently && <Loader />}</Button>
                                </div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </> : null}
            <div className={"support-container"}>
                <b>Need help? contact at <a href={"mailto:" + CONSTANTS.SUPPORT_EMAIL}>{CONSTANTS.SUPPORT_EMAIL}</a></b>
            </div>
        </AgentManagerStyle>
        <Modal
            id="modal-component"
            disableBackdropClick={false}
            open={isModalOpen}
            onClose={() => { setIsModalOpen(false); }}
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
        >
            <ModalComponentStyle>
                <div>
                    <div className={"positioning-container"}>
                        <div id="modal-title">Visa Sent!</div>
                        <div id="modal-content">
                            Notification email regarding visa(s)/rejection(s) was sent to the customer
                            <div className={"close-button-container"}><Button onClick={() => { setIsModalOpen(false); }}>Close</Button></div>
                        </div>
                    </div>
                </div>
            </ModalComponentStyle>
        </Modal>
    </>;

    function isUploadedVisa() {
        if (!lodashGet(selectedPersonToView, 'files')) {
            return false;
        }
        if (fileObjects.find((item) => item.offline)) {
            return true;
        }
        //if (lodashGet(selectedPersonToView, 'files').find((item) => item.fileType == 'visa')) {
            //return true;
        //}
        return false;
    }

    function translateVisaType(visaTypeValue) {
        if (!getLanguageValue('register-individual.stage2-select-visa-type-' + visaTypeValue).startsWith('__')) {
            return getLanguageValue('register-individual.stage2-select-visa-type-' + visaTypeValue);
        }
        return visaTypeValue;
    }

    async function startProcedure() {
        try {
            if (!selectedPersonToView) {
                return;
            }
            if (isLoaderActive()) {
                return;
            }
            setLoaders({ ...loaders, startProcedure: true });
            setErrors({ ...errors, startProcedure: false });
            let url = getApiRoot() + 'agent-api/agent-change-application-status/';
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    status: 'waiting-for-agent',
                    rejectReasons: {}
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                setSelectedPersonToView({ ...selectedPersonToView, status: 'waiting-for-agent' });
            }
            else {
                // error
                setErrors({ ...errors, startProcedure: true });
            }
        }
        catch (err) {
            // nothing to do
            setErrors({ ...errors, startProcedure: true });
        }
        setLoaders({ ...loaders, startProcedure: false });
    }


    async function glenApproveOrder() {
        try {
            if (!selectedPersonToView) {
                return;
            }
            if (isLoaderActive()) {
                return;
            }
            setLoaders({ ...loaders, startApprove: true });
            setErrors({ ...errors, startApprove: false });
            let url = getApiRoot() + 'agent-api/agent-glen/';
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    glenapprove: 1,
                    glenask:0,
                    rejectReasons: {}
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                alert("Success")
                //setSelectedPersonToView({ ...selectedPersonToView, status: 'waiting-for-agent' });
            }
            else {
                // error
                setErrors({ ...errors, startApprove: true });
            }
        }
        catch (err) {
            // nothing to do
            setErrors({ ...errors, startApprove: true });
        }
        setLoaders({ ...loaders, startApprove: false });
    }

    async function glenAskOrder() {
        try {
            if (!selectedPersonToView) {
                return;
            }
            if (isLoaderActive()) {
                return;
            }
            setLoaders({ ...loaders, startAsk: true });
            setErrors({ ...errors, startAsk: false });
            let url = getApiRoot() + 'agent-api/agent-glen/';
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    glenask: 1,
                    glenapprove:0 ,
                    rejectReasons: {}
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                alert("Sent Request For Documents")
            }
            else {
                // error
                setErrors({ ...errors, startAsk: true });
            }
        }
        catch (err) {
            // nothing to do
            setErrors({ ...errors, startAsk: true });
        }
        setLoaders({ ...loaders, startAsk: false });
    }

    function getGroupPersons() {
        let list = lodashGet(apiResult, 'data.linkedIndividualApplications');
        if (Array.isArray(list)) {
            return list.map((item, itemIndex) => (<React.Fragment key={itemIndex}><Button className={"select-person-button" + (selectedPersonToView && selectedPersonToView.key == item.key ? ' selected-person' : '')} onClick={() => selectPersonFromGroup(item)}>{isGroupPersonReady(item) ? <CheckIcon/> : null}{'#' + (itemIndex+1) + ' - ' + item.stage2Data.firstNameEnglish + ' ' + item.stage2Data.lastNameEnglish}</Button>&nbsp;&nbsp;&nbsp;</React.Fragment>));
        }
        return null;
    }

    function selectPersonFromGroup(item) {
        setSelectedPersonToView(item);
        setModifiedUserFileObjects({});
        // set files if needed
        fileObjects = [];
        for (let loop = 0 ; loop < item.files.length ; ++loop) {
            if (item.files[loop].fileType == 'visa') {
                fileObjects.push({ offline: true, fileId: item.files[loop].fileId, data: '', file: { type: '', name: item.files[loop].filename } });
            }
        }
        setFileObjects(fileObjects);
    }

    function getStage5QuestionsList(stage5Data) {
        let existingQuestionsAndAnswers = {};
        if (lodashGet(stage5Data, 'questionsAndAnswers')) {
            // save mapping of key / answer to set in next code
            for (let questionKey in stage5Data.questionsAndAnswers) {
                existingQuestionsAndAnswers[ questionKey ] = stage5Data.questionsAndAnswers[questionKey];
            }
        }

        const output = [];
        const questionsKeysArray = getLanguageValue('register-individual.stage5-questions-array').replace(/\s/g, '').split(',');
        for (let questionKeySuffix of questionsKeysArray) {
            const fullKey = 'register-individual.stage5-questions-item-' + questionKeySuffix;
            const questionValue = getLanguageValue(fullKey);
            if (questionValue) {
                output.push({ key: questionKeySuffix, value: questionValue, answer: existingQuestionsAndAnswers[questionKeySuffix] || '' });
            }
        }
        return output;
    }

    function isUploadDisabled(type) {
        if (type == 'title') {
            return lodashGet(selectedPersonToView, 'status') == 'paid';
        }
        return isLoading || lodashGet(selectedPersonToView, 'status') != 'waiting-for-agent';
    }

    async function uploadAllVisaFiles() {
        if (isLoaderActive() || isLoading || (!fileObjects.length && !fileObjectsToDelete.length)) {
            return;
        }
        let isOnline = false;
        let uploadCounter = fileObjects.length;
        let uploadFailureCounter = 0;
        for (let loop = 0 ; loop < fileObjects.length ; ++loop) {
            // run uploads
            if (!fileObjects[loop].offline) {
                isOnline = true;
                (async function(fileItemValue) {
                    try {
                        let url = getApiRoot() + 'agent-api/agent-upload-visa-files/?type=' + lodashGet(apiResult, 'data.type');
                        let recaptchaToken = await googleRecaptcha();
                        let options = {
                            ...CONSTANTS.POST_DEFAULT_OPTIONS,
                            body: JSON.stringify({
                                recaptchaToken,
                                secretAgentKey: routerProps.id,
                                key: selectedPersonToView.key,
                                filename: fileItemValue.file.name,
                                fileContent: fileItemValue.data
                            })
                        };
                        let result = await (await fetch(url, options)).json();
                        uploadCounter--;
                        if (!lodashGet(result, 'data.success')) {
                            ++uploadFailureCounter;
                        }
                        else {
                            fileItemValue.fileId = lodashGet(result, 'data.fileId');
                            fileItemValue.offline = true;
                        }
                    }
                    catch (err) {
                        // error
                    }
                    if (uploadCounter == 0) {
                        // all completed
                        setLoaders({ ...loaders, uploadAllFiles: false });
                        setIsLoading(false);
                        if (uploadFailureCounter) {
                            setErrors({ ...errors, uploadAllFiles: true });
                        }
                        setFileObjects([].concat(fileObjects));
                    }
                })(fileObjects[loop]);
            }
            else {
                uploadCounter--;
            }
        }
        let deleteCounter = fileObjectsToDelete.length;
        let deleteFailureCounter = 0;
        for (let loop = 0 ; loop < fileObjectsToDelete.length ; ++loop) {
            (async function(fileItemValue) {
                try {
                    let url = getApiRoot() + 'agent-api/agent-remove-visa-files/?type=' + lodashGet(apiResult, 'data.type');
                    let recaptchaToken = await googleRecaptcha();
                    let options = {
                        ...CONSTANTS.POST_DEFAULT_OPTIONS,
                        body: JSON.stringify({
                            recaptchaToken,
                            secretAgentKey: routerProps.id,
                            key: selectedPersonToView.key,
                            fileId: fileItemValue.fileId
                        })
                    };
                    let result = await (await fetch(url, options)).json();
                    deleteCounter--;
                    if (!lodashGet(result, 'data.success')) {
                        ++deleteFailureCounter;
                    }
                }
                catch (err) {
                    // error
                    deleteCounter--
                }
                if (deleteCounter == 0) {
                    // all completed
                    setLoaders({ ...loaders, uploadAllFiles: false });
                    setIsLoading(false);
                    if (deleteFailureCounter) {
                        setErrors({ ...errors, uploadAllFiles: true });
                    }
                    setFileObjectsToDelete([]);
                }
            })(fileObjectsToDelete[loop], loop);
        }
        if (isOnline) {
            setErrors({ ...errors, uploadAllFiles: false});
            setLoaders({ ...loaders, uploadAllFiles: true });
            setIsLoading(true);
        }
    }

    function isFinishDisabled(conf) {
        // check status is not ready or reject (submitted) and that there are submitted visa files
        if (['paid', 'ready', 'ready-without-files', 'reject'].indexOf(lodashGet(selectedPersonToView, 'status')) != -1) {
            return true;
        }
        if (!lodashGet(conf, 'ignoreFiles')) {
            let haveSavedVisa = false;
            for (let loop = 0 ; loop < fileObjects.length ; ++loop) {
                if (fileObjects[loop].offline) {
                    haveSavedVisa = true;
                }
            }
            return !haveSavedVisa;
        }
        return false;
    }

    function isRejectDisabled() {
        // check only status is not ready or reject
        if (!selectedPersonToView || ['ready', 'ready-without-files', 'reject'].indexOf(lodashGet(selectedPersonToView, 'status')) > -1) {
            return true;
        }
        return false;
    }

    function isGroupReady() {
        return lodashGet(apiResult, 'data.groupApplication.status') == 'ready';
    }

    function isGroupPersonReady(person) {
        return ['ready', 'ready-without-files'].indexOf(lodashGet(person, 'status')) != -1;
    }

    function isIndividualPassedStage(stageNumber) {
        const personStatus = lodashGet(selectedPersonToView, 'status');
        switch (stageNumber) {
            case 1:
                return ['waiting-for-agent', 'ready', 'ready-without-files', 'reject'].indexOf(personStatus) != -1;
            case 2:
                return ['waiting-for-agent', 'ready', 'ready-without-files', 'reject'].indexOf(personStatus)  != -1;
            case 3:
                return ['ready', 'ready-without-files', 'reject'].indexOf(personStatus) != -1;
            case 4:
                return ['ready', 'ready-without-files'].indexOf(personStatus) != -1;
        }
        return false;
    }

    async function finish(status) {
        let STATUS_MAPPING = {
            reject: 'reject',
            ready: 'ready',
            readyWithoutFiles: 'ready-without-files',
            rejectPermanently: 'reject'
        };
        try {
            if (isLoaderActive() || isLoading) {
                return;
            }
            if (status == 'reject') {
                let validateResult = validateReject();
                if (validateResult.length) {
                    setShowRejectWarning(true);
                    return;
                }
            }
            setShowRejectWarning(false);
            setIsLoading(true);
            setLoaders({ ...loaders, [status]: true });
            let url = getApiRoot() + 'agent-api/agent-change-application-status/';
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    status: STATUS_MAPPING[status],
                    rejectReasons: (status == 'reject' ? rejectReasons : {})
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                setSelectedPersonToView({ ...selectedPersonToView, status: STATUS_MAPPING[status] });
                if (lodashGet(apiResult, 'data.linkedIndividualApplications.length')) {
                    for (let loop = 0 ; loop < apiResult.data.linkedIndividualApplications.length ; ++loop) {
                        if (apiResult.data.linkedIndividualApplications[loop].key == selectedPersonToView.key) {
                            apiResult.data.linkedIndividualApplications[loop].status = 'ready';
                            apiResult.data.linkedIndividualApplications[loop].files = selectedPersonToView.files;
                        }
                    }
                }
                setApiResult({...apiResult});
                if (lodashGet(apiResult, 'data.type') != 'group' || lodashGet(result, 'data.groupReady')) {
                    setIsModalOpen(true);
                }

            }
            else {
                // error
                setErrors({ ...errors, finish: true });
            }
            setLoaders({ ...loaders, [status]: false });
        }
        catch (err) {
            // nothing to do
            setLoaders({ ...loaders, [status]: false });
        }
        setIsLoading(false);
    }

    function validateReject() {
        let output = [];
        let haveRejectReason = false;
        for (let key in rejectReasons) {
            if (rejectReasons[key]) {
                haveRejectReason = true;
            }
        }
        if (!haveRejectReason) {
            output.push('rejectReason');
        }
        return output;
    }

    function getPersonFileType(fileType) {
        const editedResult = lodashGet(modifiedUserFileObjects, fileType);
        const serverResult = selectedPersonToView.files.find((fileItem, fileItemIndex) => fileItem.fileType == fileType);
        if (editedResult) {
            return editedResult;
        }
        else if (serverResult) {
            return serverResult;
        }
        return false;
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic'].indexOf(fileTypeResultExtension) == -1) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-type-error') } })
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-too-small-error') } })
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-size-error') } })
                return;
            }
            // close previous crop tool if have any
            if (document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button')) {
                document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button').click();
            }
            // activate crop tool
            setTimeout(() => {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { fileContent: reader.result } })
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    async function saveUserFile(selectedPerson, fileType, loaderName) {
        setLoaders({ ...loaders, [loaderName]: true });
        setIsLoading(true);
        try {
            let url = getApiRoot() + 'agent-api/agent-replace-client-image/?type=' + (lodashGet(apiResult, 'data.type') == 'group' ? 'group' : 'individual');
            let recaptchaToken = await googleRecaptcha();
            let fileTypeResult = await getFileType(modifiedUserFileObjects[fileType].fileContent);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    filesArray: [{ fileContent: modifiedUserFileObjects[fileType].fileContent, filename: 'default.' + fileTypeResultExtension, fileType }]
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // update object

                if (lodashGet(apiResult, 'data.type') == 'group') {
                    let list = lodashGet(apiResult, 'data.linkedIndividualApplications');
                    let matchedIndividual = false;
                    if (Array.isArray(list)) {
                        for (let loop = 0 ; loop < list.length && !matchedIndividual ; ++loop) {
                            if (list[loop].key == selectedPerson.key) {
                                // match
                                matchedIndividual = true;
                                let matchedFile = false;
                                for (let fLoop = 0 ; fLoop < list[loop].files.length && !matchedFile ; ++fLoop) {
                                    if (list[loop].files[fLoop].fileType == fileType) {
                                        matchedFile = true;
                                        list[loop].files[fLoop].fileContent = modifiedUserFileObjects[fileType].fileContent;
                                    }
                                }
                                if (!matchedFile) {
                                    // add new
                                    list[loop].files.push({ fileContent: modifiedUserFileObjects[fileType].fileContent, fileType });
                                }
                            }
                        }
                        setApiResult({ ...apiResult });
                    }

                }
                else {
                    // individual
                    let individualObject = lodashGet(apiResult, 'data.individualApplication');
                    let matchedFile = false;
                    for (let fLoop = 0 ; fLoop < individualObject.files.length && !matchedFile ; ++fLoop) {
                        if (individualObject.files[fLoop].fileType == fileType) {
                            matchedFile = true;
                            individualObject.files[fLoop].fileContent = modifiedUserFileObjects[fileType].fileContent;
                        }
                    }
                    if (!matchedFile) {
                        individualObject.files.push({ fileContent: modifiedUserFileObjects[fileType].fileContent, fileType });
                    }
                    setApiResult({ ...apiResult });
                }
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fileType]: null });
            }
            setLoaders({ ...loaders, [loaderName]: false });
            setIsLoading(false);
        }
        catch (err) {
            setLoaders({ ...loaders, [loaderName]: false });
            setIsLoading(false);
        }
    }

    function isLoaderActive() {
        for (let loaderKey in loaders) {
            if (loaders[loaderKey]) {
                return true;
            }
        }
        return false;
    }

    function cropHandlerCallback(croppedImage) {
        setModifiedUserFileObjects({ ...modifiedUserFileObjects, [this.fileType]: (croppedImage !== false ? { fileContent: croppedImage, finished: true } : null) });
    }
}

const AgentManagerStyle = styled.div`
  text-align: center;
  .general-error-message {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .MuiButtonBase-root.MuiButtonBase-root {
    text-transform: none;
  }
  .logo-image {
    max-height: 60px;
  }

    .divglen {
      width: 300px;
      height: 300px;
      text-align: center;
      margin: auto;
      background: yellow;
      box-sizing:border-box;
      -moz-box-sizing:border-box;
      -webkit-box-sizing:border-box;
      border: 10px solid transparent;
  }

    .divglen:hover {
        border: 10px solid red;
    }



  .checked-icon-container {
    display: inline-block;
    position: relative;
    top: 6px;
  }
  .main-table-component {
    position: relative;
    width: 100%;
  }
  .sticky-header {
    top: 0;
    position: sticky;
    background-color: white;
    z-index: 2;
  }
  .group-short-instructions-inner-container {
    display: inline-block;
    text-align: left;
  }
  .start-procedure-button-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .error-message-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .persons-buttons-column {
    padding-top: 10px;
    width: 250px;
  }
  .select-person-button {
    border: 1px solid black;
    margin-bottom: 10px;
    &.selected-person {
      background-color: lightgreen;
    }
  }
  .files-information-container {
    text-align: left;
    h2 {
      text-align: center;
    }
  }
  .table-main-h2 {
    background-color: rgba(0,0,255,0.1);
    margin: 0;
  }
  .file-item-container {
    display: inline-block;
    border: 1px dashed black;
    b {
      width: 100%;
      display: inline-block;
      border: 1px dashed black;
    }
  }
  .client-pictures-component {
    &.passport {
      max-width: 500px;
    }
    &.passport-face {
      max-width: 350px;
    }
    &.birth-certificate {
      max-width: 600px;
    }
    &.parent-passport {
      max-width: 500px;
    }
    &.passport-backside {
      max-width: 500px;
    }
  }
  .agent-action-button {
    border: 1px solid black;
  }

  .agent-accept-action-button {
    border: 1px solid black;
    background-color: green;
  }

  .agent-ask-action-button {
    border: 1px solid black;
    background-color: grey;
  }
  .user-files-action-button {
    width: 100%;
    border: 1px solid black;
    &.final-upload-confirmation {
      font-weight: bold;
    }
  }
  .upload-error-message {
    max-width: 560px;
  }
  #passport-file-upload-field, #passport-face-file-upload-field, #birth-certificate-file-upload-field, #parent-passport-file-upload-field, #passport-backside-file-upload-field {
    display: none;
  }
  .warning-color {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .stage-header {
    background-color: #f5f5f5;
    border: 1px solid #aaa;
    cursor: pointer;
    height: 60px;
  }
  .stage-content {
    border: 1px solid #aaa;
    border-top: none;
  }
  .stage-content-container {
    padding: 20px;
  }
  .fields-row-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      margin-bottom: 20px;
      &.last {
        margin-bottom: 0;
      }
    }
  }
  .special-text-display {
    color: red;
  }
  .table-component {
    border: 1px solid #aaa;
  }
  .table-header-component {
    background-color: #ddd;
  }
  .citizen-of-any-other-country-question {
    margin-top: 20px;
    padding-bottom: 5px;
    text-align: left;
  }
  .citizen-of-other-country-table {
    td {
      word-break: break-word;
    }
  }
  .stage3-phones-container {
    margin-top: 20px;
    text-align: left;
  }
  .stage5-questions-container {
    text-align: left;
  }
  .disabled-notification {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .upload-all-files-button {
    min-width: 150px;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .upload-visa-dropzone-container {
    max-width: 80%;
    margin: 0 auto;
  }
  .upload-visa-dropzone-container.inactive {
    position: relative;
    z-index: -50;
    opacity: 0.4;
  }
  .upload-error-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .finish-button, .reject-button {
    font-weight: bold;
    min-width: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .finish-button {
    border: 1px solid green;
    color: green;
  }
  .reject-button {
    border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reason-error-text {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reasons-container {
    display: inline-block;
    &.warning {
      border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .support-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

const ModalComponentStyle = styled.div`
    outline: none;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .positioning-container {
      width: 60%;
      position: relative;
      top: 30px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        width: auto;
        top: 0;
      }
    }
    #modal-content {
      padding: 10px 10px;
      background-color: white;
    }
    #modal-title {
      height: 50px;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-button-container {
      text-align: center;
      > button {
        background-color: #fc3;
      }
    }
`;

const AgentManagerPageBody = connect(
    (state) => ({
    }),
    {})(AgentManagerPageBodyComponent);

export default AgentManagerPageBody;
