import React from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import lodashGet from 'lodash/get';
import CONSTANTS from '../common/constants';
import { CSS_COLORS } from '../common/cssColors';
import {getLanguageValue} from "../common/functions";

function TableComponent(props) {
    const dispatch = useDispatch();
    let {
        config,
        data
    } = props;

    // EXAMPLE PROPS DATA
    // config = {
    //     columns: [
    //         { key: '#', title: '#' , render: function(row) { for (let loop = 0 ; loop < data.length ; ++loop) { if (data[loop] == row) { return loop + 1; } } return  } },
    //         { key: 'a', title: getLanguageValue('register-individual.stage7-table-header-date-of-birth') },
    //         { key: 'b', title: 'ze', render: function(row) { return 'x' + row.b; } },
    //         { hiddenInMobile: true, key: 'foo', title: 'q', render: function(row) { return <div>View</div>; } }
    //     ],
    //     mobileFooterPerItem: function(item) {
    //         return <div>z</div>;
    //     }
    // };
    // data = [
    //     { a: '5', b: '7' },
    //     { a: '8', b: 'F' }
    // ];

    if (!lodashGet(data, 'length') || !lodashGet(config, 'columns.length')) {
        return null;
    }

    return <TableComponentStyle>
        <table className="desktop-details-table">
            <thead>
                <tr>
                    {config.columns.map((item, itemIndex) => (<th key={itemIndex}>{item.title}</th>))}
                </tr>
            </thead>
            <tbody>
                {data.map((dataItem, dataItemIndex) => {
                    return <tr key={dataItemIndex} className={"table-row" + (dataItemIndex % 2 == 0 ? ' even' : ' odd')}>
                        {config.columns.map((columnConfigItem, columnConfigItemIndex) => {
                            return <td key={columnConfigItemIndex}>{columnConfigItem.render ? columnConfigItem.render(dataItem, dataItemIndex) : dataItem[columnConfigItem.key]}</td>;
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
        <div className="mobile-forms-table">
            {getMobileData().map(function(rowItem, rowItemIndex) {
                return <React.Fragment key={rowItemIndex}>
                    {rowItem.cols.map(function(colItem, colItemIndex) {
                        return <React.Fragment key={colItemIndex}><div className={"row-item " + (rowItemIndex % 2 == 0 ? ' even' : ' odd')}>
                            <div className="row-name-container">{colItem.title}</div>
                            <div className="row-value-container">{colItem.value}</div>
                        </div></React.Fragment>;
                    })}
                    {config.mobileFooterPerItem ? config.mobileFooterPerItem(rowItem.item) : null}
                </React.Fragment>;
            })}
        </div>
    </TableComponentStyle>;

    function getMobileData() {
        let newOutput = [];
        for (let loop = 0 ; loop < data.length ; ++loop) {
            const item = data[loop];
            let newColsObject = [];
            for (let cLoop = 0 ; cLoop < config.columns.length ; ++cLoop) {
                let colConfig = config.columns[cLoop];
                if (!colConfig.hiddenInMobile) {
                    newColsObject.push({
                        title: colConfig.title,
                        value: colConfig.render ? colConfig.render(item, loop) : item[colConfig.key]
                    });
                }
            }
            newOutput.push({
                cols: newColsObject,
                item
            });
        }
        return newOutput;
    }
}

const TableComponentStyle = styled.div`
  .update-button, .view-button, .update-rejected-data-button {
    border: 1px solid black;
    height: 35px;
    width: 100%;
    white-space: nowrap;
  }
  .desktop-details-table {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
    width: 80%;
    min-width: 840px;
    border-collapse: collapse; 
    border: 1px solid black;
    .table-row.even {
      background-color: #eee;
    }
    .table-row.odd {
      background-color: #ccc;
    }
    th {
      background-color: #1c3764;
      color: white;
      //border-left: 1px solid black;
      //border-bottom: 1px solid black;
      padding-top: 5px;
      padding-bottom: 5px;
      //margin: 0;
      border: 1px solid black;
    }
    td {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .mobile-forms-table {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      display: none;
    }
    .row-item {
      display: flex;
      justify-content: space-between;
      &.odd {
        background-color: #ccc;
      }
      &.even {
        background-color: #eee;
      }
      .row-name-container {
        font-weight: bold;
        font-size: 16px;
      }
      .row-value-container {
        font-size: 13px;
      }
    }
    .application-controls-buttons-container {
      margin-top: 10px;
      margin-bottom: 25px;
      > div {
        width: 48%;
        display: inline-block;
        vertical-align: top;
      }
      .buttons-spacer {
        width: 4%;
      }
      .max-width {
        width: 100%;
      }
    }
  }

  // common style (for status)
  .progress-circles-container {
    white-space: nowrap;
  }
  .reject-circle-component {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .circle-component {
    color: #1460aa;
  }

  .status-br {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
  }
  .form-status {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      position: relative;
      top: -7px;
    }
    &.unpaid, &.reject, &.expired {
      font-weight: bold;
      color: ${CSS_COLORS.FIELD_WARNING};
    }
    &.ready, &.paid, &.ready-without-files {
      font-weight: bold;
      color: green;
    }
  }
`;

const GenericTableComponent = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
    }),
    {})(TableComponent);

export default GenericTableComponent;