import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { getLanguageValue, isElementInView} from "../common/functions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import { ZIndices } from '../common/zIndices';
import CONSTANTS from '../common/constants';

function NeedHelpFloatingComponent(props) {
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ floatingHelpComponentVisible, setFloatingHelpComponentVisible ] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    useEffect(() => {
        setTimeout(onScroll, 50);
    }, []);

    return <><NeedHelpFloatingComponentStyle>
        <Fade in={!isMobile && floatingHelpComponentVisible}><span className={"need-help-floating-component"}><a href="" onClick={needHelp}>{getLanguageValue('announcements.need-help')}</a></span></Fade>
    </NeedHelpFloatingComponentStyle></>;

    function onScroll() {
        if (isMobile) {
            return;
        }
        const isHelpComponentVisible = isElementInView(document.querySelector('.need-help-component'));
        if (floatingHelpComponentVisible != !isHelpComponentVisible) {
            setFloatingHelpComponentVisible(!isHelpComponentVisible);
        }
    }

    function needHelp(e) {
        e.preventDefault();
        try {
            document.getElementById('need-help-component').scrollIntoView();
        }
        catch (err) {
            // nothing to do
        }
    }
}

const NeedHelpFloatingComponentStyle = styled.div`
 .need-help-floating-component {
    position: fixed;
    z-index: ${ZIndices.NEED_HELP_COMPONENT};
    transform: rotate(90deg);
    transform-origin: left top 0;
    left: 100%;
    top: 200px;
    color: #00416a;
    background-color: #fc3;
    padding: 5px;
    font-size: 20px;
    white-space: nowrap;
    &.hidden {
      display: none;
    }
  }
`;

export default NeedHelpFloatingComponent;