import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import he from "date-fns/locale/he";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

function GenericFieldComponent(props) {
    const {
        type,
        selectLabel,
        selectId,
        selectOptions,
        selectFormClassName,
        fieldProps
    } = props;

    if (type == 'date') {
        return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={window.LANGUAGE.key == 'he' ? he : null}>
            <KeyboardDatePicker {...fieldProps} error={!!fieldProps.error || false} />
        </MuiPickersUtilsProvider>
    }
    else if (type == 'select') {
        // remove helperText from passed props
        let filteredFieldProps = {...fieldProps};
        delete filteredFieldProps.helperText;
        return <FormControl className={selectFormClassName || ''}>
            <InputLabel htmlFor={selectId}>{selectLabel}</InputLabel>
            <Select {...filteredFieldProps} >
                {selectOptions.map((selectOptionItem, selectOptionItemIndex) => (<option key={selectOptionItemIndex} value={selectOptionItem.value} disabled={selectOptionItem.isDisabled}>{selectOptionItem.displayText}</option>))}
            </Select>
            <FormHelperText error={!!fieldProps.error || false}>{fieldProps.helperText}</FormHelperText>
        </FormControl>;
    }
    else {
        return <TextField {...fieldProps} error={!!fieldProps.error || false} />;
    }
}

export default GenericFieldComponent;