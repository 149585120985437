import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    /* main styles */
    body, html {
      margin: 0;
      padding: 0;
      font-family: 'Assistant', Arial, Tahoma, sans-serif;
      height: 100%;
      width: 100%;
    }
    html.rtl {
        direction: rtl;
    }
    html.ltr {
        direction: ltr;
    }
    #root {
      height: 100%;
    }
    
    
    /* material UI overrides */
    .MuiButtonBase-root.MuiButtonBase-root {
        text-transform: none;
    }
`;

export default GlobalStyle;