import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CONSTANTS from '../common/constants';
import CloseIcon from '@material-ui/icons/Close';
import Fade from "@material-ui/core/Fade";
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStylesDesktop = makeStyles(theme => ({
    modal: {
        top: '150px !important',
        width: '70%',
        margin: '0 auto'
    },
    paper: {

    },
}));

const useStylesMobile = makeStyles(theme => ({
    modal: {
        top: '150px !important',
        width: '90%',
        margin: '0 auto'
    },
    paper: {

    },
}));

function ModalComponent(props) {
    const {
        useFade,
        modalClassName,
        disableBackdropClick,
        onClose,
        isOpen,
        showTitleCloseButton,
        title,
        content
    } = props;
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    let classesDesktop = useStylesDesktop();
    let classesMobile = useStylesMobile();

    return <>
        <Modal
            className={(isMobile ? classesMobile.modal : classesDesktop.modal) + (modalClassName ? (' ' + modalClassName) : '')}
            id="modal-component"
            disableBackdropClick={disableBackdropClick || false}
            open={isOpen}
            onClose={() => { onClose('close'); }}
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
        >
            {useFade ? <Fade in={isOpen}>
                    <ModalComponentStyle>
                        <div>
                            <div className={"positioning-container"}>
                                <div id="modal-title">{title} {showTitleCloseButton == true && <Button id="modal-title-close-button" onClick={() => { onClose('button'); }}><CloseIcon className="title-close-icon" /></Button>}</div>
                                <div id="modal-content">{content}</div>
                            </div>
                        </div>
                    </ModalComponentStyle>
                </Fade> :
                <ModalComponentStyle>
                    <div>
                        <div className={"positioning-container"}>
                            <div id="modal-title">{title} {showTitleCloseButton == true && <Button id="modal-title-close-button" onClick={() => { onClose('button'); }}><CloseIcon className="title-close-icon" /></Button>}</div>
                            <div id="modal-content">{content}</div>
                        </div>
                    </div>
                </ModalComponentStyle>
            }

        </Modal>
    </>;
}

const ModalComponentStyle = styled.div`
    outline: none;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .positioning-container {
      position: relative;
      width: 100%;
    }
    #modal-content {
      padding: 10px 10px;
      background-color: white;
    }
    #modal-title {
      min-height: 50px;
      padding-right: 65px;
      padding-left: 65px;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #modal-title-close-button {
      position: absolute;
      right: 0;
    }
`;

export default ModalComponent;